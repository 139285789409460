import React from 'react';

const SideMenu = () => {
  return (
    <>
      <div id="body_overlay"></div>

      <div
        id="Side_slide"
        className="right dark"
        data-width="250"
        aria-expanded="false"
        role="banner"
        aria-label="responsive menu"
      >
        <div className="close-wrapper">
          <a href="#" aria-label="menu close icon" className="close">
            <i className="icon-cancel-fine"></i>
          </a>
        </div>

        <div className="extras">
          <div
            className="extras-wrapper"
            role="navigation"
            aria-label="extras menu"
          ></div>
        </div>

        <div
          className="lang-wrapper"
          role="navigation"
          aria-label="language menu"
        ></div>

        <div
          className="menu_wrapper"
          role="navigation"
          aria-label="main menu"
        ></div>

        <nav id="social-menu" className="menu-action-bar-container">
          <ul id="menu-action-bar-1" className="social-menu">
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6918">
              <a href="/about-us/contact-us/">
                Contact Us
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7990">
              <a href="/careers/">Careers</a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6919">
              <a href="https://dashboard.spartancapitalbroker.com">
                Client Login
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div id="mfn-gdpr" data-alignment="bottom" data-direction="horizontal">
        <div className="mfn-gdpr-image">
          <img
            src="/wp-content/themes/betheme/images/cookies.png"
            alt="Home"
            width="64"
            height="64"
          />
        </div>
        <div className="mfn-gdpr-content">
          This website uses cookies to improve your experience. By using this
          website you agree to our <a href="#">Data Protection Policy</a>.
        </div>
        {/* <a
          className="mfn-gdpr-readmore"
          href="https://spartancapital.com/privacy-policy/"
        >
          Read more
        </a> */}
        <button
          className="mfn-gdpr-button"
          data-cookie-days="365"
          data-animation="slide"
        >
          Accept all
        </button>
      </div>
    </>
  );
};

export default SideMenu;
