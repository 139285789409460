import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import SideMenu from '../components/SideMenu';
import Header from '../components/Header';
const HomePage = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.RS_MODULES) {
      if (window.RS_MODULES.modules['revslider21']) {
        window.RS_MODULES.modules['revslider21'].once = false;
        window.revapi2 = undefined;
        if (window.RS_MODULES.checkMinimal) window.RS_MODULES.checkMinimal();
      }
    }
  }, []);

  useEffect(() => {
    const initRevSlider = () => {
      if (
        typeof window !== 'undefined' &&
        window.RS_MODULES !== undefined &&
        window.RS_MODULES.modules['revslider32'] !== undefined
      ) {
        window.RS_MODULES.modules['revslider32'].once = false;
        window.revapi3 = undefined;
        if (window.RS_MODULES.checkMinimal !== undefined) {
          window.RS_MODULES.checkMinimal();
        }
      }
    };

    // Ensure the revslider plugin initializes after the DOM is fully loaded
    document.addEventListener('DOMContentLoaded', initRevSlider);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('DOMContentLoaded', initRevSlider);
    };
  }, []);

  // useEffect(() => {
  //  setREVStartSize({
  //     c: 'rev_slider_2_1',
  //     rl: [1240, 1024, 778, 480],
  //     el: [600],
  //     gw: [1200],
  //     gh: [600],
  //     type: 'standard',
  //     justify: '',
  //     layout: 'fullscreen',s
  //     offsetContainer: '',
  //     offset: '',
  //     mh: '0',
  //   });

  //   if (
  //     window.RS_MODULES !== undefined &&
  //     window.RS_MODULES.modules !== undefined &&
  //     window.RS_MODULES.modules['revslider21'] !== undefined
  //   ) {
  //     window.RS_MODULES.modules['revslider21'].once = false;
  //     window.revapi2 = undefined;
  //     if (window.RS_MODULES.checkMinimal !== undefined) {
  //       window.RS_MODULES.checkMinimal();
  //     }
  //   }
  // }, []); // Dependency array ensures this runs only once after component mounts.

  return (
    <body className="home page-template-default page page-id-8 wp-embed-responsive woocommerce-block-theme-has-button-styles  color-custom content-brightness-light input-brightness-light style-default keyboard-support underline-links warning-links button-animation-fade layout-full-width no-content-padding header-classic header-fw sticky-header sticky-tb-color ab-show menu-link-color menuo-right menuo-arrows subheader-both-center footer-copy-center responsive-overflow-x-mobile mobile-tb-center mobile-side-slide mobile-mini-mr-ll mobile-header-mini mobile-icon-user-ss mobile-icon-wishlist-ss mobile-icon-search-ss mobile-icon-wpml-ss mobile-icon-action-ss be-page-8 be-reg-2759">
      <div id="Wrapper">
        <Header />
        <div id="Content" role="main">
          <div className="content_wrapper clearfix">
            <main className="sections_group">
              <div className="entry-content" itemProp="mainContentOfPage">
                <div className="mfn-builder-content mfn-default-content-buider">
                  <section
                    className="section mcb-section mfn-default-section mcb-section-g8qaj8f8 no-margin-h no-margin-v full-width-ex-mobile hide-mobile default-width"
                    style={{}}
                  >
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-g8qaj8f8">
                      <div
                        className="wrap mcb-wrap mcb-wrap-j7m7skpe one tablet-one laptop-one mobile-one clearfix"
                        data-desktop-col="one"
                        data-laptop-col="laptop-one"
                        data-tablet-col="tablet-one"
                        data-mobile-col="mobile-one"
                        style={{}}
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-j7m7skpe mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div
                            className="column mcb-column mcb-item-njq5e3h24 one laptop-one tablet-one mobile-one column_slider_plugin"
                            style={{}}
                          >
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-njq5e3h24 mcb-item-slider_plugin-inner">
                              <div className="mfn-main-slider mfn-rev-slider">
                                <p className="rs-p-wp-fix"></p>
                                <rs-module-wrap
                                  id="rev_slider_2_1_wrapper"
                                  data-source="gallery"
                                  style={{
                                    visibility: 'hidden',
                                    background: 'transparent',
                                    padding: 0,
                                  }}
                                >
                                  <rs-module
                                    id="rev_slider_2_1"
                                    style={{}}
                                    data-version="6.7.9"
                                  >
                                    <rs-slides
                                      style={{
                                        overflow: 'hidden',
                                        position: 'absolute',
                                      }}
                                    >
                                      <rs-slide
                                        style={{ position: 'absolute' }}
                                        data-key="rs-23"
                                        data-title="Slide"
                                        data-anim="ms:1000;r:0;"
                                        data-in="o:0;"
                                        data-out="a:false;"
                                      >
                                        <img
                                          src="/wp-content/plugins/revslider/sr6/assets/assets/dummy.png"
                                          alt=""
                                          title="Home"
                                          className="rev-slidebg tp-rs-img rs-lazyload"
                                          data-lazyload="/wp-content/plugins/revslider/sr6/assets/assets/transparent.png"
                                          data-no-retina
                                        />
                                        <rs-bgvideo
                                          data-video="w:100%;h:100%;nse:false;rwd:false;l:true;ptimer:false;vfc:true;"
                                          data-mp4="/wp-content/uploads/2022/11/times-square-stock-market-ticker-background-SBV-300264851-HD_YouTube.mp4"
                                        ></rs-bgvideo>
                                        <rs-layer
                                          id="slider-2-slide-23-layer-3"
                                          data-type="shape"
                                          data-rsp_ch="on"
                                          data-xy="x:c;y:c;"
                                          data-text="w:normal;"
                                          data-dim="w:5835px;h:5117px;"
                                          data-frame_999="o:0;st:w;"
                                          style={{
                                            zIndex: 8,
                                            backgroundColor:
                                              'rgba(24,43,85,0.39)',
                                          }}
                                        ></rs-layer>
                                        <rs-layer
                                          id="slider-2-slide-23-layer-4"
                                          className="rev-scroll-btn rev-b-span-light"
                                          data-type="button"
                                          data-color="rgba(255, 255, 255, 0.42)"
                                          data-rsp_ch="on"
                                          data-xy="x:c;y:c;yo:110px;"
                                          data-text="w:normal;s:17;l:20;ls:1;"
                                          data-dim="w:35px;h:55px;minw:35px;minh:55px;"
                                          data-actions="o:click;a:scrollbelow;sp:1000ms;e:power1.inOut;"
                                          data-padding="t:22;r:14;b:22;l:14;"
                                          data-border="bos:solid;boc:rgba(255,255,255,0.75);bow:3px,3px,3px,3px;bor:23px,23px,23px,23px;"
                                          data-frame_999="o:0;st:w;"
                                          style={{
                                            zIndex: 9,
                                            fontFamily: 'Roboto',
                                            textTransform: 'uppercase',
                                          }}
                                        >
                                          <span></span>
                                          <span></span>
                                          <span></span>
                                        </rs-layer>
                                        <rs-layer
                                          id="slider-2-slide-23-layer-5"
                                          data-type="image"
                                          data-rsp_ch="on"
                                          data-xy="x:c;y:c;yo:-197px;"
                                          data-text="w:normal;"
                                          data-dim="w:628px;h:203px;"
                                          data-frame_999="o:0;st:w;"
                                          style={{ zIndex: 10 }}
                                        >
                                          <img
                                            src="/wp-content/plugins/revslider/sr6/assets/assets/dummy.png"
                                            alt=""
                                            className="tp-rs-img rs-lazyload"
                                            width="628"
                                            height="203"
                                            data-lazyload="/wp-content/uploads/2022/11/1-white-spartan-logo-slider.png"
                                            data-no-retina
                                          />
                                        </rs-layer>
                                        <rs-layer
                                          id="slider-2-slide-23-layer-6"
                                          data-type="image"
                                          data-rsp_ch="on"
                                          data-xy="x:c;y:c;yo:20px;"
                                          data-text="w:normal;"
                                          data-dim="w:897px;h:187px;"
                                          data-frame_999="o:0;st:w;"
                                          style={{ zIndex: 11 }}
                                        >
                                          <img
                                            src="/wp-content/plugins/revslider/sr6/assets/assets/dummy.png"
                                            alt="Building Relationships one client at a time saying image"
                                            className="tp-rs-img rs-lazyload"
                                            width="897"
                                            height="187"
                                            data-lazyload="/wp-content/uploads/2022/11/1-building-client-relationships.png"
                                            data-no-retina
                                          />
                                        </rs-layer>
                                      </rs-slide>
                                    </rs-slides>
                                  </rs-module>
                                  <script>
                                    {`setREVStartSize({c: 'rev_slider_2_1',rl:[1240,1024,778,480],el:[600],gw:[1200],gh:[600],type:'standard',justify:'',layout:'fullscreen',offsetContainer:'',offset:'',mh:"0"});
                            if (window.RS_MODULES!==undefined && window.RS_MODULES.modules!==undefined && window.RS_MODULES.modules["revslider21"]!==undefined) {
                              window.RS_MODULES.modules["revslider21"].once = false;
                              window.revapi2 = undefined;
                              if (window.RS_MODULES.checkMinimal!==undefined) window.RS_MODULES.checkMinimal()
                            }`}
                                  </script>
                                </rs-module-wrap>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    className="section mcb-section mfn-default-section mcb-section-rka9gsfj no-margin-h no-margin-v full-width-ex-mobile hide-desktop hide-laptop hide-tablet default-width"
                    style={{}}
                  >
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-rka9gsfj">
                      <div
                        className="wrap mcb-wrap mcb-wrap-gh0cpa33zr one tablet-one laptop-one mobile-one clearfix"
                        data-desktop-col="one"
                        data-laptop-col="laptop-one"
                        data-tablet-col="tablet-one"
                        data-mobile-col="mobile-one"
                        style={{}}
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-gh0cpa33zr mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div
                            className="column mcb-column mcb-item-ygn2r9dgk one laptop-one tablet-one mobile-one column_slider_plugin"
                            style={{}}
                          >
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-ygn2r9dgk mcb-item-slider_plugin-inner">
                              <div className="mfn-main-slider mfn-rev-slider">
                                <p className="rs-p-wp-fix"></p>
                                <rs-module-wrap
                                  id="rev_slider_3_2_wrapper"
                                  data-source="gallery"
                                  style={{
                                    visibility: 'hidden',
                                    background: 'transparent',
                                    padding: 0,
                                  }}
                                >
                                  <rs-module
                                    id="rev_slider_3_2"
                                    style={{}}
                                    data-version="6.7.9"
                                  >
                                    <rs-slides
                                      style={{
                                        overflow: 'hidden',
                                        position: 'absolute',
                                      }}
                                    >
                                      <rs-slide
                                        style={{ position: 'absolute' }}
                                        data-key="rs-24"
                                        data-title="Slide"
                                        data-anim="ms:1000;r:0;"
                                        data-in="o:0;"
                                        data-out="a:false;"
                                      >
                                        <img
                                          src="/wp-content/plugins/revslider/sr6/assets/assets/dummy.png"
                                          alt=""
                                          title="Home"
                                          className="rev-slidebg tp-rs-img rs-lazyload"
                                          data-lazyload="/wp-content/plugins/revslider/sr6/assets/assets/transparent.png"
                                          data-no-retina
                                        />
                                        <rs-bgvideo
                                          data-video="w:100%;h:100%;nse:false;rwd:false;l:true;ptimer:false;vfc:true;"
                                          data-mp4="/wp-content/uploads/2022/11/times-square-stock-market-ticker-background-SBV-300264851-HD_YouTube.mp4"
                                        ></rs-bgvideo>
                                        <rs-layer
                                          id="slider-3-slide-24-layer-3"
                                          data-type="shape"
                                          data-rsp_ch="on"
                                          data-xy="x:c;y:m;"
                                          data-text="w:normal;s:20,20,20,19;l:0,0,0,24;"
                                          data-dim="w:5835px,5835px,5835px,5601px;h:5117px,5117px,5117px,4912px;"
                                          data-frame_999="o:0;st:w;"
                                          style={{
                                            zIndex: 8,
                                            backgroundColor:
                                              'rgba(24,43,85,0.39)',
                                          }}
                                        ></rs-layer>
                                        <rs-layer
                                          id="slider-3-slide-24-layer-4"
                                          className="rev-scroll-btn rev-b-span-light"
                                          data-type="button"
                                          data-color="rgba(255, 255, 255, 0.42)"
                                          data-rsp_ch="on"
                                          data-xy="x:c;y:m;yo:110px,110px,110px,105px;"
                                          data-text="w:normal;s:17,17,17,16;l:20,20,20,19;ls:1,1,1,0;"
                                          data-dim="w:35px,35px,35px,33px;h:55px,55px,55px,52px;minw:35px,35px,35px,none;minh:55px,55px,55px,none;"
                                          data-actions="o:click;a:scrollbelow;sp:1000ms;e:power1.inOut;"
                                          data-padding="t:22,22,22,21;r:14,14,14,13;b:22,22,22,21;l:14,14,14,13;"
                                          data-border="bos:solid;boc:rgba(255,255,255,0.75);bow:3px,3px,3px,3px;bor:23px,23px,23px,23px;"
                                          data-frame_999="o:0;st:w;"
                                          style={{
                                            zIndex: 9,
                                            fontFamily: 'Roboto',
                                            textTransform: 'uppercase',
                                          }}
                                        >
                                          <span></span>
                                          <span></span>
                                          <span></span>
                                        </rs-layer>
                                        <rs-layer
                                          id="slider-3-slide-24-layer-5"
                                          data-type="image"
                                          data-rsp_ch="on"
                                          data-xy="x:c;xo:1px,1px,1px,0;y:m;yo:-150px,-150px,-150px,-144px;"
                                          data-text="w:normal;s:20,20,20,19;l:0,0,0,24;"
                                          data-dim="w:473px,473px,473px,454px;h:153px,153px,153px,146px;"
                                          data-frame_999="o:0;st:w;"
                                          style={{ zIndex: 10 }}
                                        >
                                          <img
                                            src="/wp-content/plugins/revslider/sr6/assets/assets/dummy.png"
                                            alt=""
                                            className="tp-rs-img rs-lazyload"
                                            width="628"
                                            height="203"
                                            data-lazyload="/wp-content/uploads/2022/11/1-white-spartan-logo-slider.png"
                                            data-no-retina
                                          />
                                        </rs-layer>
                                        <rs-layer
                                          id="slider-3-slide-24-layer-6"
                                          data-type="image"
                                          data-rsp_ch="on"
                                          data-xy="x:c;y:m;yo:20px,20px,20px,19px;"
                                          data-text="w:normal;s:20,20,20,19;l:0,0,0,24;"
                                          data-dim="w:460px,460px,460px,441px;h:96px,96px,96px,92px;"
                                          data-frame_999="o:0;st:w;"
                                          style={{ zIndex: 11 }}
                                        >
                                          <img
                                            src="/wp-content/plugins/revslider/sr6/assets/assets/dummy.png"
                                            alt="Building Relationships one client at a time saying image"
                                            className="tp-rs-img rs-lazyload"
                                            width="897"
                                            height="187"
                                            data-lazyload="/wp-content/uploads/2022/11/1-building-client-relationships.png"
                                            data-no-retina
                                          />
                                        </rs-layer>
                                      </rs-slide>
                                    </rs-slides>
                                  </rs-module>
                                  <script>
                                    {`setREVStartSize({c: 'rev_slider_3_2',rl:[1240,1240,1240,480],el:[600,600,600,720],gw:[500,500,500,480],gh:[600,600,600,600],type:'standard',justify:'',layout:'fullscreen',offsetContainer:'',offset:'',mh:"0"});
                            if (window.RS_MODULES!==undefined && window.RS_MODULES.modules!==undefined && window.RS_MODULES.modules["revslider32"]!==undefined) {
                              window.RS_MODULES.modules["revslider32"].once = false;
                              window.revapi3 = undefined;
                              if (window.RS_MODULES.checkMinimal!==undefined) window.RS_MODULES.checkMinimal()
                            }`}
                                  </script>
                                </rs-module-wrap>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    className="section mcb-section mfn-default-section mcb-section-zy9lmdd6e full-width full-width-deprecated default-width"
                    style={{ paddingTop: '90px', paddingBottom: '70px' }}
                  >
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-zy9lmdd6e">
                      <div
                        className="wrap mcb-wrap mcb-wrap-oveiujs1r one tablet-one laptop-one mobile-one valign-top clearfix"
                        data-desktop-col="one"
                        data-laptop-col="laptop-one"
                        data-tablet-col="tablet-one"
                        data-mobile-col="mobile-one"
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-oveiujs1r mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div
                            className="column mcb-column mcb-item-c8f0gpi0t one laptop-one tablet-one mobile-one column_column animate column-margin-30px"
                            data-anim-type="fadeInUp"
                          >
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-c8f0gpi0t mcb-item-column-inner">
                              <div className="column_attr mfn-inline-editor clearfix align_center">
                                <h6>
                                  Global Investment & Wealth Management
                                  Strategies
                                </h6>
                                <h2>
                                  <strong>For Long-Term Growth.</strong>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wrap mcb-wrap mcb-wrap-n71eha82w one tablet-one laptop-one mobile-one valign-top clearfix"
                        data-desktop-col="one"
                        data-laptop-col="laptop-one"
                        data-tablet-col="tablet-one"
                        data-mobile-col="mobile-one"
                        style={{ padding: '0px 2% 0px 2%' }}
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-n71eha82w mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div
                            className="column mcb-column mcb-item-7dmiaofsm one-fourth laptop-one-fourth tablet-one-fourth mobile-one column_sliding_box animate"
                            data-anim-type="fadeInUp"
                          >
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-7dmiaofsm mcb-item-sliding_box-inner">
                              <div className="sliding_box">
                                <a href="/wealth-management/" title="">
                                  <div className="photo_wrapper">
                                    <img
                                      className="scale-with-grid"
                                      src="/wp-content/uploads/2022/09/wealth-managment-blue.jpg"
                                      alt="Home"
                                    />
                                  </div>
                                  <div className="desc_wrapper">
                                    <h4 className="title">Wealth Management</h4>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="column mcb-column mcb-item-5pgegulc0 one-fourth laptop-one-fourth tablet-one-fourth mobile-one column_sliding_box animate"
                            data-anim-type="fadeInUp"
                          >
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-5pgegulc0 mcb-item-sliding_box-inner">
                              <div className="sliding_box">
                                <a href="#" title="">
                                  <div className="photo_wrapper">
                                    <img
                                      className="scale-with-grid"
                                      src="/wp-content/uploads/2022/09/investment-banking-blue.jpg"
                                      alt="Home"
                                    />
                                  </div>
                                  <div className="desc_wrapper">
                                    <h4 className="title">
                                      Investment Banking
                                    </h4>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="column mcb-column mcb-item-0hffupzcs one-fourth laptop-one-fourth tablet-one-fourth mobile-one column_sliding_box animate"
                            data-anim-type="fadeInUp"
                          >
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-0hffupzcs mcb-item-sliding_box-inner">
                              <div className="sliding_box">
                                <a href="/institutional-trading/" title="">
                                  <div className="photo_wrapper">
                                    <img
                                      className="scale-with-grid"
                                      src="/wp-content/uploads/2022/12/instututional-trading-image.jpg"
                                      alt="Home"
                                    />
                                  </div>
                                  <div className="desc_wrapper">
                                    <h4 className="title">
                                      Institutional Trading
                                    </h4>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="column mcb-column mcb-item-l8cl728z4 one-fourth laptop-one-fourth tablet-one-fourth mobile-one column_sliding_box animate"
                            data-anim-type="fadeInUp"
                          >
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-l8cl728z4 mcb-item-sliding_box-inner">
                              <div className="sliding_box">
                                <a href="#" title="">
                                  <div className="photo_wrapper">
                                    <img
                                      className="scale-with-grid"
                                      src="/wp-content/uploads/2022/12/stock-chart-homepage-button.jpg"
                                      alt="Home"
                                    />
                                  </div>
                                  <div className="desc_wrapper">
                                    <h4 className="title">Equity Research</h4>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    className="section mcb-section mfn-default-section mcb-section-ah21l28fv dark default-width bg-cover-ultrawide"
                    style={{
                      paddingTop: '160px',
                      paddingBottom: '120px',
                      backgroundAttachment: 'scroll',
                    }}
                    data-parallax="3d"
                  >
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-ah21l28fv">
                      <div
                        className="wrap mcb-wrap mcb-wrap-p8pg0vb36 one tablet-one laptop-one mobile-one valign-top clearfix"
                        data-desktop-col="one"
                        data-laptop-col="laptop-one"
                        data-tablet-col="tablet-one"
                        data-mobile-col="mobile-one"
                        style={{}}
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-p8pg0vb36 mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div
                            className="column mcb-column mcb-item-98i3df3ew one laptop-one tablet-one mobile-one column_column animate"
                            style={{}}
                            data-anim-type="fadeInUp"
                          >
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-98i3df3ew mcb-item-column-inner">
                              <div
                                className="column_attr mfn-inline-editor clearfix align_center"
                                style={{}}
                              >
                                <h1>
                                  <strong>Strength To</strong> Rely On
                                </h1>
                                <h5>
                                  A
                                  <strong>
                                    t Spartan Capital Securities, LLC we
                                    understand that guidance without trust is
                                    advice without value. We have earned our
                                    reputation one client at a time, with the
                                    knowledge that our relationships have
                                    remained successful because they are founded
                                    on trust. We achieve this by incorporating
                                    our clients' long-term goals into investment
                                    strategy, listening to and appreciating each
                                    individual needs. Our past success has been
                                    predicated on these relationships, just as
                                    our future success depends on them.
                                  </strong>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section
                    className="section mcb-section mfn-default-section mcb-section-6mirbn7lo full-width full-width-deprecated default-width"
                    style={{ backgroundColor: '#f9f9f9' }}
                  >
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-6mirbn7lo">
                      <div
                        className="wrap mcb-wrap mcb-wrap-jo1sbsbhx one tablet-one laptop-one mobile-one valign-top clearfix"
                        data-desktop-col="one"
                        data-laptop-col="laptop-one"
                        data-tablet-col="tablet-one"
                        data-mobile-col="mobile-one"
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-jo1sbsbhx mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div
                            className="column mcb-column mcb-item-cl2kc547i one laptop-one tablet-one mobile-one column_column animate"
                            data-anim-type="fadeInUp"
                          >
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-cl2kc547i mcb-item-column-inner">
                              <div className="column_attr mfn-inline-editor clearfix align_center">
                                <h6>Stay up to date with</h6>
                                <h1>
                                  <strong>Cardillo's Corner</strong>
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wrap mcb-wrap mcb-wrap-igenx02ej one tablet-one laptop-one mobile-one valign-top clearfix"
                        data-desktop-col="one"
                        data-laptop-col="laptop-one"
                        data-tablet-col="tablet-one"
                        data-mobile-col="mobile-one"
                        style={{ padding: '0px 4% 0px 4%' }}
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-igenx02ej mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div className="column mcb-column mcb-item-lv715dzkd one laptop-one tablet-one mobile-one column_blog_slider">
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-lv715dzkd mcb-item-blog_slider-inner">
                              <div
                                className="blog_slider clearfix hide-more hide-dots"
                                data-count="12"
                              >
                                <div className="blog_slider_header clearfix">
                                  <div className="slider_navigation"></div>
                                </div>
                                <ul className="blog_slider_ul">
                                  <li className="post-11619 post type-post status-publish format-standard has-post-thumbnail hentry category-cardillos-corner">
                                    <div className="item_wrapper">
                                      <div className="image_frame scale-with-grid">
                                        <div className="image_wrapper">
                                          <a href="https://ausbiz.com.au/media/-anytime-gold-dips-its-a-buying-opportunity?videoId=38240#new_tab">
                                            <img
                                              width="940"
                                              height="548"
                                              src="/wp-content/uploads/2021/11/ausbix-logo.jpg"
                                              className="scale-with-grid wp-post-image"
                                              alt="Auusbiz Logo"
                                              decoding="async"
                                              fetchpriority="high"
                                              srcSet="/wp-content/uploads/2021/11/ausbix-logo.jpg 940w, https://spartancapital.com/wp-content/uploads/2021/11/ausbix-logo-300x175.jpg 300w, https://spartancapital.com/wp-content/uploads/2021/11/ausbix-logo-768x448.jpg 768w, https://spartancapital.com/wp-content/uploads/2021/11/ausbix-logo-250x146.jpg 250w, https://spartancapital.com/wp-content/uploads/2021/11/ausbix-logo-50x29.jpg 50w, https://spartancapital.com/wp-content/uploads/2021/11/ausbix-logo-129x75.jpg 129w"
                                              sizes="(max-width:767px) 480px, (max-width:940px) 100vw, 940px"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                      <div className="date_label">
                                        October 11, 2024
                                      </div>
                                      <div className="desc">
                                        <h4>
                                          <a href="https://ausbiz.com.au/media/-anytime-gold-dips-its-a-buying-opportunity?videoId=38240#new_tab">
                                            Sign in &#8220;Anytime gold dips,
                                            it&#8217;s a buying
                                            opportunity&#8230;
                                          </a>
                                        </h4>
                                        <hr className="hr_color" />
                                        <a
                                          href="https://ausbiz.com.au/media/-anytime-gold-dips-its-a-buying-opportunity?videoId=38240#new_tab"
                                          className="button button_left has-icon"
                                        >
                                          <span className="button_icon">
                                            <i
                                              className="icon-layout"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <span className="button_label">
                                            Read more
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="post-11615 post type-post status-publish format-video has-post-thumbnail hentry category-cardillos-corner post_format-post-format-video">
                                    <div className="item_wrapper">
                                      <div className="image_frame scale-with-grid">
                                        <div className="image_wrapper">
                                          <a href="#">
                                            <img
                                              width="940"
                                              height="548"
                                              src="/wp-content/uploads/2023/03/linea-mercati.jpg"
                                              className="scale-with-grid wp-post-image"
                                              alt=""
                                              decoding="async"
                                              srcSet="/wp-content/uploads/2023/03/linea-mercati.jpg 940w, /wp-content/uploads/2023/03/linea-mercati-300x175.jpg 300w, /wp-content/uploads/2023/03/linea-mercati-768x448.jpg 768w, /wp-content/uploads/2023/03/linea-mercati-250x146.jpg 250w, /wp-content/uploads/2023/03/linea-mercati-50x29.jpg 50w, /wp-content/uploads/2023/03/linea-mercati-129x75.jpg 129w"
                                              sizes="(max-width:767px) 480px, (max-width:940px) 100vw, 940px"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                      <div className="date_label">
                                        October 11, 2024
                                      </div>
                                      <div className="desc">
                                        <h4>
                                          <a href="#">
                                            Linea Mercati Interview 10/10/24
                                          </a>
                                        </h4>
                                        <hr className="hr_color" />
                                        <a
                                          href="#"
                                          className="button button_left has-icon"
                                        >
                                          <span className="button_icon">
                                            <i
                                              className="icon-layout"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <span className="button_label">
                                            Read more
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="post-11612 post type-post status-publish format-standard has-post-thumbnail hentry category-cardillos-corner">
                                    <div className="item_wrapper">
                                      <div className="image_frame scale-with-grid">
                                        <div className="image_wrapper">
                                          <a href="#">
                                            <img
                                              width="940"
                                              height="548"
                                              src="/wp-content/uploads/2020/04/yahoo-finance.jpg"
                                              className="scale-with-grid wp-post-image"
                                              alt="Yahoo finance Logo"
                                              decoding="async"
                                              srcSet="/wp-content/uploads/2020/04/yahoo-finance.jpg 940w, /wp-content/uploads/2020/04/yahoo-finance-300x175.jpg 300w, /wp-content/uploads/2020/04/yahoo-finance-768x448.jpg 768w, /wp-content/uploads/2020/04/yahoo-finance-250x146.jpg 250w, /wp-content/uploads/2020/04/yahoo-finance-50x29.jpg 50w, /wp-content/uploads/2020/04/yahoo-finance-129x75.jpg 129w"
                                              sizes="(max-width:767px) 480px, (max-width:940px) 100vw, 940px"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                      <div className="date_label">
                                        October 10, 2024
                                      </div>
                                      <div className="desc">
                                        <h4>
                                          <a href="https://spartancapital.com/stock-markets-diverge-as-hong-kong-sinks-oil-prices-fall/">
                                            Stock markets diverge as Hong Kong
                                            sinks, oil prices fall
                                          </a>
                                        </h4>
                                        <hr className="hr_color" />
                                        <a
                                          href="https://spartancapital.com/stock-markets-diverge-as-hong-kong-sinks-oil-prices-fall/"
                                          className="button button_left has-icon"
                                        >
                                          <span className="button_icon">
                                            <i
                                              className="icon-layout"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <span className="button_label">
                                            Read more
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                                <div className="slider_pager slider_pagination"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wrap mcb-wrap mcb-wrap-0xio192gb one tablet-one laptop-one mobile-one valign-top clearfix"
                        data-desktop-col="one"
                        data-laptop-col="laptop-one"
                        data-tablet-col="tablet-one"
                        data-mobile-col="mobile-one"
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-0xio192gb mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div className="column mcb-column mcb-item-qkjguvh7w one-sixth laptop-one-sixth tablet-one-sixth mobile-one column_column">
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-qkjguvh7w mcb-item-column-inner">
                              <div className="column_attr mfn-inline-editor clearfix"></div>
                            </div>
                          </div>
                          <div className="column mcb-column mcb-item-vwhwrrzq one-third laptop-one-third tablet-one-third mobile-one column_button">
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-vwhwrrzq mcb-item-button-inner">
                              <div className="button_align align_center">
                                <a
                                  className="button button_full_width button_size_3"
                                  href="https://spartancapital.com/cardillos-corner/"
                                >
                                  <span className="button_label">
                                    View All Articles
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="column mcb-column mcb-item-yh1css4u2 one-third laptop-one-third tablet-one-third mobile-one column_button">
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-yh1css4u2 mcb-item-button-inner">
                              <div className="button_align align_center">
                                <a
                                  className="button button_full_width button_size_3"
                                  href="https://spartancapital.com/market-commentary-2024-economic-outlook/"
                                >
                                  <span className="button_label">
                                    2024 Financial Outlook
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section mcb-section the_content no_content">
                    <div className="section_wrapper">
                      <div className="the_content_wrapper"></div>
                    </div>
                  </section>

                  <section className="section section-page-footer">
                    <div className="section_wrapper clearfix">
                      <div className="column one page-pager">
                        <div className="mcb-column-inner"></div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
      <Footer />
    </body>
  );
};

export default HomePage;
