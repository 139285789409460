import React from 'react';
import SideMenu from './SideMenu';

const Header = () => {
  return (
    <>
      <SideMenu />
      <div>
        <div id="Header_wrapper" className="">
          <header id="Header">
            <div id="Action_bar">
              <div className="container">
                <div className="column one">
                  <ul className="contact_details" aria-label="contact details">
                    <li className="slogan">
                      Global investment & Wealth Management Strategies for
                      Long-Term Growth
                    </li>
                  </ul>
                  <nav id="social-menu" className="menu-action-bar-container">
                    <ul id="menu-action-bar" className="social-menu">
                      <li
                        id="menu-item-6918"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6918"
                      >
                        <a href="#">Contact Us</a>
                      </li>
                      <li
                        id="menu-item-7990"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7990"
                      >
                        <a href="#">Careers</a>
                      </li>
                      <li
                        id="menu-item-6919"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6919"
                      >
                        <a href="https://dashboard.spartancapitalbroker.com/signin">
                          Client Login
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div className="header_placeholder"></div>
            <div id="Top_bar">
              <div className="container">
                <div className="column one">
                  <div className="top_bar_left clearfix">
                    <div className="logo">
                      <a
                        id="logo"
                        href="/"
                        title="Spartan Capital Securities"
                        data-height="65"
                        data-padding="15"
                      >
                        <img
                          className="logo-main scale-with-grid"
                          src="/wp-content/uploads/2022/01/spartan-capital-logo.png"
                          data-retina="/wp-content/uploads/2022/01/spartan-capital-logo-retina.png"
                          data-height=""
                          alt="Home"
                          data-no-retina
                        />
                        <img
                          className="logo-sticky scale-with-grid"
                          src="/wp-content/uploads/2022/01/spartan-capital-logo.png"
                          data-retina="/wp-content/uploads/2022/01/spartan-capital-logo-retina.png"
                          data-height=""
                          alt="Home"
                          data-no-retina
                        />
                        <img
                          className="logo-mobile scale-with-grid"
                          src="/wp-content/uploads/2022/01/spartan-capital-logo.png"
                          data-retina="/wp-content/uploads/2022/01/spartan-capital-logo-retina.png"
                          data-height=""
                          alt="Home"
                          data-no-retina
                        />
                        <img
                          className="logo-mobile-sticky scale-with-grid"
                          src="/wp-content/uploads/2022/01/spartan-capital-logo.png"
                          data-retina="/wp-content/uploads/2022/01/spartan-capital-logo-retina.png"
                          data-height=""
                          alt="Home"
                          data-no-retina
                        />
                      </a>
                    </div>
                    <div className="menu_wrapper">
                      <a
                        className="responsive-menu-toggle"
                        aria-label="mobile menu"
                      >
                        <i className="icon-menu-fine" aria-hidden="true"></i>
                      </a>

                      <nav
                        id="menu"
                        role="navigation"
                        aria-expanded="false"
                        aria-label="Main menu"
                      >
                        <ul id="menu-main-menu" className="menu menu-main">
                          <li
                            id="menu-item-8596"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-8 current_page_item"
                          >
                            <a href="/">
                              <span>Home</span>
                            </a>
                          </li>

                          <li
                            id="menu-item-8596"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-8 current_page_item"
                          >
                            <a href="/about">
                              <span>About</span>
                            </a>
                          </li>
                          <li
                            id="menu-item-8598"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children"
                          >
                            <a href="index.html#">
                              <span>Services</span>
                            </a>
                            <ul className="sub-menu">
                              <li
                                id="menu-item-8600"
                                className="menu-item menu-item-type-post_type menu-item-object-page"
                              >
                                <a href="/management">
                                  <span>Wealth Management</span>
                                </a>
                              </li>

                              <li
                                id="menu-item-8602"
                                className="menu-item menu-item-type-post_type menu-item-object-page"
                              >
                                <a href="/trading">
                                  <span>Institutional Trading</span>
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li
                            id="menu-item-8599"
                            className="menu-item menu-item-type-post_type menu-item-object-page"
                          >
                            <a href="/transactions">
                              <span>Transactions</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div className="secondary_menu_wrapper"></div>
                  </div>
                  <div className="search_wrapper">
                    <form
                      method="get"
                      className="form-searchform"
                      action="index.html"
                    >
                      <svg
                        className="icon_search"
                        width="26"
                        viewBox="0 0 26 26"
                        aria-label="search icon"
                      >
                        <defs>
                          <style>{`.path{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:1.5px;}`}</style>
                        </defs>
                        <circle
                          className="path"
                          cx="11.35"
                          cy="11.35"
                          r="6"
                        ></circle>
                        <line
                          className="path"
                          x1="15.59"
                          y1="15.59"
                          x2="20.65"
                          y2="20.65"
                        ></line>
                      </svg>
                      <span className="mfn-close-icon icon_close" tabIndex="0">
                        <span className="icon">✕</span>
                      </span>
                      <label htmlFor="s" className="screen-reader-text">
                        Enter your search
                      </label>
                      <input
                        type="text"
                        className="field"
                        name="s"
                        autoComplete="off"
                        placeholder="Enter your search"
                        aria-label="Enter your search"
                      />
                      <input
                        type="submit"
                        className="display-none"
                        value=""
                        aria-label="Search"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>
    </>
  );
};

export default Header;
