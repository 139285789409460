import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

const InstitutionalTrading = () => {
  return (
    <body className="home page-template-default page page-id-8 wp-embed-responsive woocommerce-block-theme-has-button-styles  color-custom content-brightness-light input-brightness-light style-default keyboard-support underline-links warning-links button-animation-fade layout-full-width no-content-padding header-classic header-fw sticky-header sticky-tb-color ab-show menu-link-color menuo-right menuo-arrows subheader-both-center footer-copy-center responsive-overflow-x-mobile mobile-tb-center mobile-side-slide mobile-mini-mr-ll mobile-header-mini mobile-icon-user-ss mobile-icon-wishlist-ss mobile-icon-search-ss mobile-icon-wpml-ss mobile-icon-action-ss be-page-8 be-reg-2759">
      <div id="Wrapper">
        <Header />

        <div id="Content" role="main">
          <div className="content_wrapper clearfix">
            <main className="sections_group">
              <div className="entry-content" itemProp="mainContentOfPage">
                <div className="mfn-builder-content mfn-default-content-buider">
                  <section
                    className="section mcb-section mfn-default-section mcb-section-400ac9e81 dark bg- bg-cover-ultrawide"
                    style={{
                      paddingLeft: '2px',
                      paddingRight: '2px',
                      backgroundImage: 'unset',
                    }}
                    data-parallax="3d"
                  >
                    <img
                      className="mfn-parallax"
                      src="https://spartancapital.com/wp-content/uploads/2022/12/INSTITUTIONAL-TRADING-headers.jpg"
                      alt="parallax background"
                      style={{ transform: 'translateY(19px) translateX(-50%)' }}
                    />
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-400ac9e81">
                      <div className="wrap mcb-wrap mcb-wrap-c1c0b6227 one tablet-one laptop-one mobile-one valign-top clearfix">
                        <div className="mcb-wrap-inner mcb-wrap-inner-c1c0b6227 mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="column mcb-column mcb-item-2f84dbf64 one laptop-one tablet-one mobile-one column_column">
                            <div className="column_attr mfn-inline-editor clearfix align_center">
                              <h3 style={{ textAlign: 'left' }}>
                                Products &amp; Services
                              </h3>
                              <h1 style={{ textAlign: 'left' }}>
                                <strong>INSTITUTIONAL TRADING</strong>
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section
                    className="section mcb-section mfn-default-section mcb-section-0jyg2p31c"
                    style={{ paddingTop: '8px', backgroundColor: '#c7c8ca' }}
                  >
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-0jyg2p31c"></div>
                  </section>

                  <section
                    className="section mcb-section mfn-default-section mcb-section-jfg24285c"
                    style={{ paddingTop: '80px', paddingBottom: '80px' }}
                  >
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-jfg24285c">
                      <div className="wrap mcb-wrap mcb-wrap-a5ad77309 one tablet-one laptop-one mobile-one valign-top clearfix">
                        <div className="mcb-wrap-inner mcb-wrap-inner-a5ad77309 mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="column mcb-column mcb-item-9f1e1ba66 one laptop-one tablet-one mobile-one column_column">
                            <div className="column_attr mfn-inline-editor clearfix">
                              <h2>
                                <strong>
                                  Institutional Sales &amp; Trading
                                </strong>
                              </h2>
                              <br />
                              <p>
                                Spartan Capital’s Sales &amp; Trading Team is
                                made up of industry veterans who look to deliver
                                pertinent market insight to all our clients in a
                                timely and efficient manner. Our sales team
                                looks to serve as a true strategic partner to
                                deliver best-in-class service to our clients.
                                Spartans’ traders both contribute to and benefit
                                from the information exchange between our
                                research analysts and salespeople. The traders
                                act as an extension to our clients' trading
                                desk, implementing execution strategies aligned
                                with our client’s goals. We are an agency-only
                                trading desk putting our client’s interest at
                                the forefront while maintaining total anonymity
                                in the marketplace and providing best execution
                                practices. By combining deep fundamental
                                knowledge of companies under coverage with an
                                understanding of the technical market dynamics
                                at play, we seek to maximize alpha creation for
                                our clients. We utilize a straightforward Buy,
                                Sell, and Hold ratings system. Our research is
                                distributed to clients via email, the
                                spartancapitalbroker.com website, and via Bloomberg,
                                Thomson Reuters-Refinitiv, S&amp;P Capital IQ,
                                and FactSet terminals. We provide published
                                research reports, analyst consultations, and
                                roadshows and other meetings with corporate
                                management teams and our analysts.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section mcb-section mfn-default-section mcb-section-03b5e4a37 dark equal-height-wrap full-width full-width-deprecated">
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-03b5e4a37">
                      <div className="wrap mcb-wrap mcb-wrap-c975b775f one-second laptop-one-second mobile-one valign-top clearfix">
                        <div className="mcb-wrap-inner mcb-wrap-inner-c975b775f mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="column mcb-column mcb-item-2fm7n67mb one column_divider">
                            <div className="mcb-column-inner mfn-module-wrapper mcb-item-divider-inner">
                              <hr
                                className="no_line"
                                style={{ margin: '0 auto 0px auto' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wrap mcb-wrap mcb-wrap-df8458f7a one-second tablet-one-second mobile-one valign-top clearfix"
                        style={{
                          padding: '60px 0px 0px 0px',
                          backgroundColor: '#172e54',
                        }}
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-df8458f7a mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="column mcb-column mcb-item-700f2dff7 one">
                            <div
                              className="column_attr mfn-inline-editor clearfix"
                              style={{ padding: '50px 10% 50px 10%' }}
                            >
                              <ul>
                                <li>
                                  Utilization of FlexTrade’s Color Palette order
                                  management system with compliant order routing
                                </li>
                                <li>
                                  A robust suite of electronic and algorithmic
                                  trading tools for high/low touch trading
                                </li>
                                <li>
                                  Experience executing under both 10b-18 /
                                  10b5-1 agreements for corporations
                                </li>
                                <li>Program trading capabilities</li>
                                <li>Capacity to manage large transactions</li>
                                <li>Commission Share Arrangements (CSAs)</li>
                                <li>
                                  White label algorithmic capabilities through
                                  algorithmic partners
                                </li>
                                <li>Pre and Post trading analysis</li>
                                <li>Customized trading solutions</li>
                              </ul>
                              <p>
                                Please email{' '}
                                <a
                                  href="mailto:sales&amp;trading@spartancapital.com"
                                  style={{ color: '#999999' }}
                                >
                                  sales&amp;trading@spartancapital.com
                                </a>{' '}
                                for more information
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section
                    className="section mcb-section mfn-default-section mcb-section-2uioox8nt equal-height-wrap"
                    style={{
                      paddingTop: '60px',
                      paddingBottom: '40px',
                      backgroundColor: '#f7f7f7',
                    }}
                  >
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-2uioox8nt">
                      <div className="wrap mcb-wrap mcb-wrap-8wz86kr6e one-third laptop-one-third valign-middle clearfix">
                        <div className="mcb-wrap-inner mcb-wrap-inner-8wz86kr6e mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="column mcb-column mcb-item-gi80tvvx9 one">
                            <div className="column_attr mfn-inline-editor clearfix align_center">
                              <h2>&nbsp;</h2>
                              <h2>
                                <strong>Services</strong>
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wrap mcb-wrap mcb-wrap-n5e51xujh one-third laptop-one-third valign-top clearfix">
                        <div className="mcb-wrap-inner mcb-wrap-inner-n5e51xujh mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="column mcb-column mcb-item-87519fnqr one column_button">
                            <a
                              className="button scroll button_full_width button_size_1"
                              href="/institutional-trading/#fixed"
                            >
                              <span className="button_label">Fixed Income</span>
                            </a>
                          </div>
                          <div className="column mcb-column mcb-item-0lq80swq6 one column_button">
                            <a
                              className="button scroll button_full_width button_size_1"
                              href="/institutional-trading/#Syndicate"
                            >
                              <span className="button_label">Syndicate</span>
                            </a>
                          </div>
                          <div className="column mcb-column mcb-item-723lhky4j one column_button">
                            <a
                              className="button scroll button_full_width button_size_1"
                              href="/institutional-trading/#Capital"
                            >
                              <span className="button_label">
                                Capital Introduction
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="wrap mcb-wrap mcb-wrap-gwj13rhn one-third laptop-one-third valign-top clearfix">
                        <div className="mcb-wrap-inner mcb-wrap-inner-gwj13rhn mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="column mcb-column mcb-item-hgmm3pytu one column_button">
                            <a
                              className="button scroll button_full_width button_size_1"
                              href="/institutional-trading/#Research"
                            >
                              <span className="button_label">Research</span>
                            </a>
                          </div>
                          <div className="column mcb-column mcb-item-gstocerk6 one column_button">
                            <a
                              className="button scroll button_full_width button_size_1"
                              href="/institutional-trading/#Corporate"
                            >
                              <span className="button_label">
                                Corporate Access
                              </span>
                            </a>
                          </div>
                          <div className="column mcb-column mcb-item-37yn8grab one column_button">
                            <a
                              className="button scroll button_full_width button_size_1"
                              href="/institutional-trading/#Asset"
                            >
                              <span className="button_label">
                                Asset Allocation
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/* Add remaining sections in the same format */}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
      <Footer />
    </body>
  );
};

export default InstitutionalTrading;
