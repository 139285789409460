import { Routes as BrowserRoutes, Route, Navigate } from 'react-router-dom';
import Home from '../pages/Home';
import WealthManagement from '../pages/WealthManagement';
import InstitutionalTrading from '../pages/InstitutionalTrading';
import Transactions from '../pages/Transactions';
import About from '../pages/About';
const AppRoutes = () => {
  return (
    <BrowserRoutes>
      <Route path="/home" element={<Home />} />
      <Route index element={<Navigate to="/home" />} />
      <Route path="/about" element={<About />} />
      <Route path="/management" element={<WealthManagement />} />
      <Route path="/trading" element={<InstitutionalTrading />} />
      <Route path="/transactions" element={<Transactions />} />
    </BrowserRoutes>
  );
};

export default AppRoutes;
