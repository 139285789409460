import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
const WealthManagement = () => {
  return (
    <body className="home page-template-default page page-id-8 wp-embed-responsive woocommerce-block-theme-has-button-styles  color-custom content-brightness-light input-brightness-light style-default keyboard-support underline-links warning-links button-animation-fade layout-full-width no-content-padding header-classic header-fw sticky-header sticky-tb-color ab-show menu-link-color menuo-right menuo-arrows subheader-both-center footer-copy-center responsive-overflow-x-mobile mobile-tb-center mobile-side-slide mobile-mini-mr-ll mobile-header-mini mobile-icon-user-ss mobile-icon-wishlist-ss mobile-icon-search-ss mobile-icon-wpml-ss mobile-icon-action-ss be-page-8 be-reg-2759">
      <div id="Wrapper">
        <Header />
        <div id="Content" role="main">
          <div class="content_wrapper clearfix">
            <div id="Content" role="main">
              <div className="content_wrapper clearfix">
                <main className="sections_group">
                  <div className="entry-content" itemProp="mainContentOfPage">
                    <div className="mfn-builder-content mfn-default-content-buider">
                      <section
                        className="section mcb-section mfn-default-section mcb-section-4c789c580 dark bg- bg-cover-ultrawide"
                        style={{ backgroundImage: 'unset' }}
                        data-parallax="3d"
                      >
                        
                        <img
                          className="mfn-parallax"
                          src="https://spartancapital.com/wp-content/uploads/2022/09/WEALTH-MANAGEMENT-header.jpg"
                          alt="parallax background"
                          style={{
                            transform: 'translateY(-35px) translateX(-50%)',
                          }}
                        />
                        <div className="mcb-background-overlay"></div>
                        <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-4c789c580">
                          <div
                            className="wrap mcb-wrap mcb-wrap-9e3a9318e one tablet-one laptop-one mobile-one valign-top clearfix"
                            data-desktop-col="one"
                            data-laptop-col="laptop-one"
                            data-tablet-col="tablet-one"
                            data-mobile-col="mobile-one"
                          >
                            <div className="mcb-wrap-inner mcb-wrap-inner-9e3a9318e mfn-module-wrapper mfn-wrapper-for-wraps">
                              <div className="mcb-wrap-background-overlay"></div>
                              <div className="column mcb-column mcb-item-2b9ea0169 one laptop-one tablet-one mobile-one column_column">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-2b9ea0169 mcb-item-column-inner">
                                  <div className="column_attr mfn-inline-editor clearfix align_center">
                                    <h3 style={{ textAlign: 'left' }}>
                                      Products &amp; Services
                                    </h3>
                                    <h1 style={{ textAlign: 'left' }}>
                                      <strong>WEALTH MANAGEMENT</strong>
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section
                        className="section mcb-section mfn-default-section mcb-section-28e822d2f"
                        style={{
                          paddingTop: '8px',
                          backgroundColor: '#c7c8ca',
                        }}
                      >
                        <div className="mcb-background-overlay"></div>
                        <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-28e822d2f"></div>
                      </section>

                      <section
                        className="section mcb-section mfn-default-section mcb-section-e0dd58981"
                        style={{ paddingTop: '80px' }}
                      >
                        <div className="mcb-background-overlay"></div>
                        <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-e0dd58981">
                          <div
                            className="wrap mcb-wrap mcb-wrap-f7c92ebf0 one tablet-one laptop-one mobile-one valign-top clearfix"
                            data-desktop-col="one"
                            data-laptop-col="laptop-one"
                            data-tablet-col="tablet-one"
                            data-mobile-col="mobile-one"
                          >
                            <div className="mcb-wrap-inner mcb-wrap-inner-f7c92ebf0 mfn-module-wrapper mfn-wrapper-for-wraps">
                              <div className="mcb-wrap-background-overlay"></div>
                              <div className="column mcb-column mcb-item-2156d0c32 one laptop-one tablet-one mobile-one column_column">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-2156d0c32 mcb-item-column-inner">
                                  <div className="column_attr mfn-inline-editor clearfix">
                                    <h2>
                                      <strong>Global Wealth Management:</strong>
                                    </h2>
                                    <h2>Our Unique Wealth Generation System</h2>
                                    <br />
                                    Global wealth management begins with
                                    identifying your investment objectives, risk
                                    tolerance, and liquidity requirements and
                                    customizing an approach that serves both
                                    your short and long-term needs.
                                    <br />
                                    <br /> Our well-orchestrated financial
                                    management strategy seeks to afford our
                                    clients the ability to capitalize on
                                    favorable investment opportunities.
                                    <br />
                                    <br /> Spartan Capital’s unique global
                                    relationships, combined with our effective
                                    risk management and wealth generation
                                    strategies, ensure a comprehensive financial
                                    approach for our clients.
                                    <br />
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section
                        className="section mcb-section mfn-default-section mcb-section-e627b356e equal-height-wrap"
                        style={{
                          paddingTop: '50px',
                          paddingBottom: '40px',
                          backgroundColor: '#f7f7f7',
                        }}
                      >
                        <div className="mcb-background-overlay"></div>
                        <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-e627b356e">
                          <div
                            className="wrap mcb-wrap mcb-wrap-28f68251e one-fourth tablet-one-fourth laptop-one-fourth mobile-one valign-middle clearfix"
                            data-desktop-col="one-fourth"
                            data-laptop-col="laptop-one-fourth"
                            data-tablet-col="tablet-one-fourth"
                            data-mobile-col="mobile-one"
                          >
                            <div className="mcb-wrap-inner mcb-wrap-inner-28f68251e mfn-module-wrapper mfn-wrapper-for-wraps">
                              <div className="mcb-wrap-background-overlay"></div>
                              <div className="column mcb-column mcb-item-c1cf6c721 one laptop-one tablet-one mobile-one column_column">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-c1cf6c721 mcb-item-column-inner">
                                  <div className="column_attr mfn-inline-editor clearfix align_center">
                                    <h2>
                                      <strong>Services</strong>
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="wrap mcb-wrap mcb-wrap-701f41435 one-fourth tablet-one-fourth laptop-one-fourth mobile-one valign-top clearfix"
                            data-desktop-col="one-fourth"
                            data-laptop-col="laptop-one-fourth"
                            data-tablet-col="tablet-one-fourth"
                            data-mobile-col="mobile-one"
                          >
                            <div className="mcb-wrap-inner mcb-wrap-inner-701f41435 mfn-module-wrapper mfn-wrapper-for-wraps">
                              <div className="mcb-wrap-background-overlay"></div>
                              <div className="column mcb-column mcb-item-7a25b7bb1 one laptop-one tablet-one mobile-one column_button">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-7a25b7bb1 mcb-item-button-inner">
                                  <a
                                    className="button scroll button_full_width button_size_1"
                                    href="/wealth-management/#stocks"
                                    title=""
                                  >
                                    <span className="button_label">Stocks</span>
                                  </a>
                                </div>
                              </div>

                              <div className="column mcb-column mcb-item-2531f3b28 one laptop-one tablet-one mobile-one column_button">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-2531f3b28 mcb-item-button-inner">
                                  <a
                                    className="button scroll button_full_width button_size_1"
                                    href="/wealth-management/#mutual"
                                    title=""
                                  >
                                    <span className="button_label">
                                      Mutual Funds
                                    </span>
                                  </a>
                                </div>
                              </div>

                              <div className="column mcb-column mcb-item-9b76d9bbb one laptop-one tablet-one mobile-one column_button">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-9b76d9bbb mcb-item-button-inner">
                                  <a
                                    className="button scroll button_full_width button_size_1"
                                    href="/wealth-management/#margin"
                                    title=""
                                  >
                                    <span className="button_label">
                                      Margin Loans
                                    </span>
                                  </a>
                                </div>
                              </div>

                              <div className="column mcb-column mcb-item-d070dd865 one laptop-one tablet-one mobile-one column_button">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-d070dd865 mcb-item-button-inner">
                                  <a
                                    className="button scroll button_full_width button_size_1"
                                    href="/wealth-management/#retirement"
                                    title=""
                                  >
                                    <span className="button_label">
                                      Individual Retirement Accounts
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="wrap mcb-wrap mcb-wrap-83d262a37 one-fourth tablet-one-fourth laptop-one-fourth mobile-one valign-top clearfix"
                            data-desktop-col="one-fourth"
                            data-laptop-col="laptop-one-fourth"
                            data-tablet-col="tablet-one-fourth"
                            data-mobile-col="mobile-one"
                          >
                            <div className="mcb-wrap-inner mcb-wrap-inner-83d262a37 mfn-module-wrapper mfn-wrapper-for-wraps">
                              <div className="mcb-wrap-background-overlay"></div>
                              <div className="column mcb-column mcb-item-9209b6a1e one laptop-one tablet-one mobile-one column_button">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-9209b6a1e mcb-item-button-inner">
                                  <a
                                    className="button scroll button_full_width button_size_1"
                                    href="/wealth-management/#bonds"
                                    title=""
                                  >
                                    <span className="button_label">Bonds</span>
                                  </a>
                                </div>
                              </div>

                              <div className="column mcb-column mcb-item-1ce070497 one laptop-one tablet-one mobile-one column_button">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-1ce070497 mcb-item-button-inner">
                                  <a
                                    className="button scroll button_full_width button_size_1"
                                    href="/wealth-management/#exchange"
                                    title=""
                                  >
                                    <span className="button_label">
                                      Exchange Traded Funds
                                    </span>
                                  </a>
                                </div>
                              </div>

                              <div className="column mcb-column mcb-item-e3e050871 one laptop-one tablet-one mobile-one column_button">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-e3e050871 mcb-item-button-inner">
                                  <a
                                    className="button scroll button_full_width button_size_1"
                                    href="/wealth-management/#syndicate"
                                    title=""
                                  >
                                    <span className="button_label">
                                      Syndicate
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="wrap mcb-wrap mcb-wrap-885f71136 one-fourth tablet-one-fourth laptop-one-fourth mobile-one valign-top clearfix"
                            data-desktop-col="one-fourth"
                            data-laptop-col="laptop-one-fourth"
                            data-tablet-col="tablet-one-fourth"
                            data-mobile-col="mobile-one"
                          >
                            <div className="mcb-wrap-inner mcb-wrap-inner-885f71136 mfn-module-wrapper mfn-wrapper-for-wraps">
                              <div className="mcb-wrap-background-overlay"></div>
                              <div className="column mcb-column mcb-item-c36241342 one laptop-one tablet-one mobile-one column_button">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-c36241342 mcb-item-button-inner">
                                  <a
                                    className="button scroll button_full_width button_size_1"
                                    href="/wealth-management/#options"
                                    title=""
                                  >
                                    <span className="button_label">
                                      Options
                                    </span>
                                  </a>
                                </div>
                              </div>

                              <div className="column mcb-column mcb-item-71888c64e one laptop-one tablet-one mobile-one column_button">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-71888c64e mcb-item-button-inner">
                                  <a
                                    className="button scroll button_full_width button_size_1"
                                    href="/wealth-management/#reits"
                                    title=""
                                  >
                                    <span className="button_label">REITs</span>
                                  </a>
                                </div>
                              </div>

                              <div className="column mcb-column mcb-item-345ca31a3 one laptop-one tablet-one mobile-one column_button">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-345ca31a3 mcb-item-button-inner">
                                  <a
                                    className="button scroll button_full_width button_size_1"
                                    href="/wealth-management/#private"
                                  >
                                    .
                                  </a>
                                  <a
                                    className="button scroll button_full_width button_size_1"
                                    href="/wealth-management/#private"
                                    title=""
                                  >
                                    <span className="button_label">
                                      Private Offerings
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section className="section mcb-section mfn-default-section mcb-section-690356a8b equal-height-wrap full-width full-width-deprecated">
                        <div className="mcb-background-overlay"></div>
                        <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-690356a8b">
                          <div
                            className="wrap mcb-wrap mcb-wrap-1b7686ac3 one-second tablet-one-second laptop-one-second mobile-one valign-top clearfix"
                            data-desktop-col="one-second"
                            data-laptop-col="laptop-one-second"
                            data-tablet-col="tablet-one-second"
                            data-mobile-col="mobile-one"
                            style={{
                              padding: '0px 35px 0px 0px',
                              backgroundImage:
                                'url(https://spartancapital.com/wp-content/uploads/2020/07/people-calculating.jpg)',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                            }}
                          >
                            <div className="mcb-wrap-inner mcb-wrap-inner-1b7686ac3 mfn-module-wrapper mfn-wrapper-for-wraps">
                              <div className="mcb-wrap-background-overlay"></div>
                              <div className="column mcb-column mcb-item-cc8288114 one laptop-one tablet-one mobile-one column_divider">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-cc8288114 mcb-item-divider-inner">
                                  <hr
                                    className="no_line"
                                    style={{ margin: '0 auto 600px auto' }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="wrap mcb-wrap mcb-wrap-dff6b9119 one-second tablet-one-second laptop-one-second mobile-one valign-middle clearfix"
                            data-desktop-col="one-second"
                            data-laptop-col="laptop-one-second"
                            data-tablet-col="tablet-one-second"
                            data-mobile-col="mobile-one"
                          >
                            <div className="mcb-wrap-inner mcb-wrap-inner-dff6b9119 mfn-module-wrapper mfn-wrapper-for-wraps">
                              <div className="mcb-wrap-background-overlay"></div>
                              <div className="column mcb-column mcb-item-c9bb2d09a one laptop-one tablet-one mobile-one column_column">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-c9bb2d09a mcb-item-column-inner">
                                  <div
                                    className="column_attr mfn-inline-editor clearfix"
                                    style={{ padding: '50px 10% 50px 10%' }}
                                  >
                                    <h2>
                                      <strong>The Spartan Capital</strong>{' '}
                                      <br />
                                      Wealth Generation System Includes:
                                    </h2>
                                    <ul className="ul1">
                                      <li className="li4">
                                        <span className="s3">
                                          Managing each client’s financial
                                          portfolio with a clear understanding
                                          of short and long-term financial
                                          objectives, risk tolerance, tax
                                          requirements and liquidity needs.
                                        </span>
                                      </li>
                                      <li className="li4">
                                        <span className="s3">
                                          Customizing an investment strategy and
                                          wealth generation plan to maintain
                                          each client’s desired risk level and
                                          achieve maximum return.
                                        </span>
                                      </li>
                                      <li className="li4">
                                        <span className="s3">
                                          Creating a working relationship with
                                          each client’s professional team to
                                          develop a wealth management plan for a
                                          myriad of investment vehicles as part
                                          of a global financial plan.
                                        </span>
                                      </li>
                                      <li className="li4">
                                        <span className="s3">
                                          Continuously monitoring each client’s
                                          financial plan and investment
                                          portfolio, consistently measuring
                                          results, making adjustments as
                                          required.
                                        </span>
                                      </li>
                                    </ul>
                                    <p className="p3">
                                      <span className="s1">
                                        Financial planning and wealth management
                                        decisions must be fluid, subject to
                                        evolution as clients grow older, as
                                        children reach adulthood and as
                                        circumstances or financial markets
                                        change. Clients must always be aware
                                        that there are risks inherent to
                                        investing, and not all investments are
                                        suitable for every investor. Your
                                        Financial Consultant can provide you
                                        with additional information, including
                                        more complete details on investment
                                        objectives, as well as the risks, fees,
                                        charges and expenses associated with
                                        investing.
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section className="section mcb-section mfn-default-section mcb-section-7d17268f9 equal-height-wrap full-width full-width-deprecated">
                        <div className="mcb-background-overlay"></div>
                        <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-7d17268f9">
                          <div
                            className="wrap mcb-wrap mcb-wrap-3fe758e19 one-second tablet-one-second laptop-one-second mobile-one valign-top clearfix"
                            data-desktop-col="one-second"
                            data-laptop-col="laptop-one-second"
                            data-tablet-col="tablet-one-second"
                            data-mobile-col="mobile-one"
                            style={{
                              padding: '60px 6% 50px 6%',
                              backgroundColor: '#f7f7f7',
                            }}
                          >
                            <div className="mcb-wrap-inner mcb-wrap-inner-3fe758e19 mfn-module-wrapper mfn-wrapper-for-wraps">
                              <div className="mcb-wrap-background-overlay"></div>
                              <div className="column mcb-column mcb-item-0f6d7dc55 one laptop-one tablet-one mobile-one column_column">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-0f6d7dc55 mcb-item-column-inner">
                                  <div
                                    className="column_attr mfn-inline-editor clearfix"
                                    style={{ padding: '50px 0px 50px 0px' }}
                                  >
                                    <p>
                                      <strong>
                                        Spartan Capital has expanded our suite
                                        of financial services to include Spartan
                                        Capital Private Wealth Management and
                                        Spartan Capital Insurance Services LLC.
                                      </strong>
                                      <br />
                                      <br />
                                      Spartan Capital Private Wealth Management
                                      Services provides clients with access to a
                                      wide range of investments coupled with
                                      professional advice and guidance to
                                      develop strategic financial solutions –
                                      all for a single fee, based on the value
                                      of your account.
                                      <br />
                                      <br />
                                      Spartan Capital Insurance Services LLC
                                      offers insurance products from a variety
                                      of sponsors. Our experienced team of
                                      licensed professionals provides clients
                                      with customized advice on the full
                                      spectrum of insurance options to create
                                      personalized protection plans.
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="column mcb-column mcb-item-b3c121ae5 one laptop-one tablet-one mobile-one column_button">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-b3c121ae5 mcb-item-button-inner">
                                  <div className="button_align align_center">
                                    <a
                                      className="button button_full_width button_size_3"
                                      href="/corporate-affiliates/private-wealth-management/"
                                      style={{
                                        backgroundColor: '#182e54!important',
                                        color: '#ffffff',
                                      }}
                                      title=""
                                    >
                                      <span className="button_label">
                                        Spartan Capital Private Wealth
                                        Management
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div className="column mcb-column mcb-item-18fe127ba one laptop-one tablet-one mobile-one column_button">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-18fe127ba mcb-item-button-inner">
                                  <div className="button_align align_center">
                                    <a
                                      className="button button_full_width button_size_3"
                                      href="/corporate-affiliates/insurance-services-llc/"
                                      style={{
                                        backgroundColor: '#182e54!important',
                                        color: '#ffffff',
                                      }}
                                      title=""
                                    >
                                      <span className="button_label">
                                        Spartan Capital Insurance Services LLC
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="wrap mcb-wrap mcb-wrap-706b37ccd one-second tablet-one-second laptop-one-second mobile-one valign-top clearfix"
                            data-desktop-col="one-second"
                            data-laptop-col="laptop-one-second"
                            data-tablet-col="tablet-one-second"
                            data-mobile-col="mobile-one"
                            style={{
                              padding: '0px 35px 0px 0px',
                              backgroundImage:
                                'url(https://spartancapital.com/wp-content/uploads/2022/12/statue-of-liberty.jpg)',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                            }}
                          >
                            <div className="mcb-wrap-inner mcb-wrap-inner-706b37ccd mfn-module-wrapper mfn-wrapper-for-wraps">
                              <div className="mcb-wrap-background-overlay"></div>
                              <div className="column mcb-column mcb-item-d6d35fef9 one laptop-one tablet-one mobile-one column_divider">
                                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-d6d35fef9 mcb-item-divider-inner">
                                  <hr
                                    className="no_line"
                                    style={{ margin: '0 auto 700px auto' }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      {/* Continue sections as needed for the rest */}
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default WealthManagement;
