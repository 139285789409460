// import React from 'react';

// const Footer = () => {
//   return (
//     <footer id="Footer" className="clearfix mfn-footer" role="contentinfo">
//       <div className="footer_action">
//         <div className="container">
//           <div className="column one mobile-one">
//             <div className="mcb-column-inner">
//               <img
//                 className="aligncenter size-full wp-image-6984"
//                 src="https://spartancapital.com/wp-content/uploads/2022/01/Spartan-logo-footer-white.png"
//                 alt=""
//                 width="300"
//                 height="95"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="widgets_wrapper">
//         <div className="container">
//           <div className="column mobile-one tablet-one-fourth one-fourth">
//             <div className="mcb-column-inner">
//               <aside id="block-23" className="widget widget_block">
//                 <strong>Spartan Capital</strong>
//                 <hr className="no_line" style={{ margin: '0 auto 7px auto' }} />
//                 <a href="about">About Us</a> <br />
//                 <a href="/transactions">Transactions</a> <br />
//                 <a href="#">Philanthropy</a> <br />
//                 <a href="#">Press &amp; Media</a> <br />
//                 <a href="#">Contact Us</a>
//               </aside>
//             </div>
//           </div>
//           <div className="column mobile-one tablet-one-fourth one-fourth">
//             <div className="mcb-column-inner">
//               <aside id="block-22" className="widget widget_block">
//                 <strong>Services</strong>
//                 <hr className="no_line" style={{ margin: '0 auto 7px auto' }} />
//                 <a href="wealth-management/">Wealth Management</a> <br />
//                 <a href="#">Investment Banking</a> <br />
//                 <a href="/trading/">Institutional Trading</a> <br />
//                 <a href="#">Equity Research</a> <br />
//                 <a href="#">Special Purpose Vehicles</a>
//               </aside>
//             </div>
//           </div>
//           <div className="column mobile-one tablet-one-fourth one-fourth">
//             <div className="mcb-column-inner">
//               <aside
//                 id="custom_html-4"
//                 className="widget_text widget widget_custom_html"
//               >
//                 <div className="textwidget custom-html-widget">
//                   <strong>Finra Links</strong>
//                   <hr
//                     className="no_line"
//                     style={{ margin: '0 auto 7px auto' }}
//                   />
//                   <a
//                     href="https://brokercheck.finra.org/"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <img
//                       className="alignnone size-full wp-image-3546"
//                       src="https://spartancapital.com/wp-content/uploads/2020/07/brokers-check.png"
//                       alt=""
//                       width="164"
//                       height="40"
//                     />
//                   </a>
//                   <hr
//                     className="no_line"
//                     style={{ margin: '0 auto 12px auto' }}
//                   />
//                   <a href="#" target="_blank" rel="noopener noreferrer">
//                     <img
//                       className="alignnone size-full wp-image-3549"
//                       src="https://spartancapital.com/wp-content/uploads/2020/07/Senior-investors-white.png"
//                       alt=""
//                       width="141"
//                       height="40"
//                     />
//                   </a>
//                 </div>
//               </aside>
//             </div>
//           </div>
//           <div className="column mobile-one tablet-one-fourth one-fourth">
//             <div className="mcb-column-inner">
//               <aside
//                 id="custom_html-2"
//                 className="widget_text widget widget_custom_html"
//               >
//                 <div className="textwidget custom-html-widget">
//                   <strong>Contact Info.</strong>
//                   <hr
//                     className="no_line"
//                     style={{ margin: '0 auto 7px auto' }}
//                   />
//                   <a href="mailto:someone@example.com">
//                     <span className="__cf_email__">someone@example.com</span>
//                   </a>
//                   <hr
//                     className="no_line"
//                     style={{ margin: '0 auto 8px auto' }}
//                   />
//                   <strong>Tel:</strong>{' '}
//                   <a href="tel:8777727818">(877) 772-7818</a> <br />
//                   <strong>Tel:</strong>{' '}
//                   <a href="tel:2122930123">(212) 293-0123</a> <br />
//                   <strong>Fax:</strong>{' '}
//                   <a href="tel:2127854565">(212) 785-4565</a>
//                 </div>
//               </aside>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="footer_copy">
//         <div className="container">
//           <div className="column one mobile-one">
//             <div className="mcb-column-inner">
//               <a
//                 id="back_to_top"
//                 className="footer_button"
//                 href="#top"
//                 aria-label="Back to top icon"
//               >
//                 <i className="icon-up-open-big"></i>
//               </a>
//               <div className="copyright">
//                 <center>
//                   <a
//                     href="#"
//                     aria-label="button with icon"
//                     className="icon_bar icon_bar_facebook icon_bar_small"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <span className="t">
//                       <i className="icon-facebook"></i>
//                     </span>
//                     <span className="b">
//                       <i className="icon-facebook"></i>
//                     </span>
//                   </a>
//                   <a
//                     href="#"
//                     aria-label="button with icon"
//                     className="icon_bar icon_bar_linkedin icon_bar_small"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <span className="t">
//                       <i className="icon-linkedin"></i>
//                     </span>
//                     <span className="b">
//                       <i className="icon-linkedin"></i>
//                     </span>
//                   </a>
//                   <a
//                     href="#"
//                     aria-label="button with icon"
//                     className="icon_bar icon_bar_twitter icon_bar_small"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <span className="t">
//                       <i className="icon-twitter"></i>
//                     </span>
//                     <span className="b">
//                       <i className="icon-twitter"></i>
//                     </span>
//                   </a>
//                 </center>
//                 <br />
//                 <a href="#">Privacy Policy/CCPA</a> |{' '}
//                 <a href="#/">Accessibility</a> |{' '}
//                 <a href="#">Business Continuity Statement</a> |{' '}
//                 <a href="#">US Patriot Act</a> |{' '}
//                 <a href="#">SEC Order Handling Disclosure</a> | <br />
//                 <a href="#">Other Disclosures</a> | <a href="#">Legal Policy</a>{' '}
//                 | <a href="#/">Independent Franchise Opportunities</a> |{' '}
//                 <a href="https://spartancapital.com/careers/">Careers</a> |{' '}
//                 <a href="https://spartancapital.com/about-us/contact-us/">
//                   Contact Us
//                 </a>
//                 <br />
//                 <br /> Member of{' '}
//                 <a
//                   href="http://www.finra.org/"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   FINRA
//                 </a>{' '}
//                 &{' '}
//                 <a
//                   href="https://www.sipc.org/"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   SIPC
//                 </a>{' '}
//                 <a
//                   href="http://www.msrb.org/"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   MSRB
//                 </a>{' '}
//                 registered
//                 <br />
//                 <br />© 2023 Spartan Capital. All Rights Reserved | Powered by:{' '}
//                 <a
//                   href="https://ideastreammarketing.com/"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   Idea Stream Marketing
//                 </a>
//               </div>
//               <ul className="social"></ul>
//             </div>
//           </div>
//         </div>
//       </div>
//       <!-- Smartsupp Live Chat script -->
// <script type="text/javascript">
// var _smartsupp = _smartsupp || {};
// _smartsupp.key = 'bba3c5426d3a00d9a70dd9263d1a6fe16401c592';
// window.smartsupp||(function(d) {
//   var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
//   s=d.getElementsByTagName('script')[0];c=d.createElement('script');
//   c.type='text/javascript';c.charset='utf-8';c.async=true;
//   c.src='https://www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
// })(document);
// </script>
// <noscript> Powered by <a href=“https://www.smartsupp.com” target=“_blank”>Smartsupp</a></noscript>
//     </footer>
//   );
// };

// export default Footer;


import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const spartanLinks = [
    { text: 'About Us', path: '/about' },
    { text: 'Transactions', path: '/transactions' },
    { text: 'Philanthropy', path: '#' },
    { text: 'Press & Media', path: '#' },
    { text: 'Contact Us', path: '#' }
  ];

  const servicesLinks = [
    { text: 'Wealth Management', path: '/wealth-management/' },
    { text: 'Investment Banking', path: '#' },
    { text: '/trading/', text: 'Institutional Trading' },
    { text: 'Equity Research', path: '#' },
    { text: 'Special Purpose Vehicles', path: '#' }
  ];

  const finraLinks = [
    { src: 'wp-content/uploads/2020/07/brokers-check.png', alt: 'Broker Check', href: 'https://brokercheck.finra.org/' },
    { src: 'wp-content/uploads/2020/07/Senior-investors-white.png', alt: 'Senior Investors', href: '#' }
  ];

  return (
    <footer id="Footer" className="clearfix mfn-footer" role="contentinfo">
      <div className="footer_action">
        <div className="container">
          <div className="column one mobile-one">
            <div className="mcb-column-inner">
              <img
                className="aligncenter size-full wp-image-6984"
                src="https://spartancapital.com/wp-content/uploads/2022/01/Spartan-logo-footer-white.png"
                alt="Spartan Capital Logo"
                width="300"
                height="95"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="widgets_wrapper">
        <div className="container">
          <div className="column mobile-one tablet-one-fourth one-fourth">
            <div className="mcb-column-inner">
              <aside className="widget widget_block">
                <strong>Spartan Capital</strong>
                <hr className="no_line" style={{ margin: '0 auto 7px auto' }} />
                {spartanLinks.map(link => (
                  <Link key={link.text} to={link.path}>{link.text}</Link>
                ))}
              </aside>
            </div>
          </div>
          <div className="column mobile-one tablet-one-fourth one-fourth">
            <div className="mcb-column-inner">
              <aside className="widget widget_block">
                <strong>Services</strong>
                <hr className="no_line" style={{ margin: '0 auto 7px auto' }} />
                {servicesLinks.map(link => (
                  <Link key={link.text} to={link.path}>{link.text}</Link>
                ))}
              </aside>
            </div>
          </div>
          <div className="column mobile-one tablet-one-fourth one-fourth">
            <div className="mcb-column-inner">
              <aside className="widget_text widget widget_custom_html">
                <div className="textwidget custom-html-widget">
                  <strong>Finra Links</strong>
                  <hr className="no_line" style={{ margin: '0 auto 7px auto' }} />
                  {finraLinks.map(link => (
                    <a key={link.alt} href={link.href} target="_blank" rel="noopener noreferrer">
                      <img
                        className="alignnone size-full wp-image-3546"
                        src={link.src}
                        alt={link.alt}
                        width="164"
                        height="40"
                      />
                    </a>
                  ))}
                </div>
              </aside>
            </div>
          </div>
          <div className="column mobile-one tablet-one-fourth one-fourth">
            <div className="mcb-column-inner">
              <aside className="widget_text widget widget_custom_html">
                <div className="textwidget custom-html-widget">
                  <strong>Contact Info.</strong>
                  <hr className="no_line" style={{ margin: '0 auto 7px auto' }} />
                  <a href="mailto:support@spartancapitalbroker.com"><span className="__cf_email__">support@spartancapitalbroker</span></a>
                  <hr className="no_line" style={{ margin: '0 auto 8px auto' }} />
                  
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_copy">
        <div className="container">
          <div className="column one mobile-one">
            <div className="mcb-column-inner">
              <a id="back_to_top" className="footer_button" href="#top" aria-label="Back to top icon">
                <i className="icon-up-open-big"></i>
              </a>
              <div className="copyright">
                <center>
                  <a href="#" className="icon_bar icon_bar_facebook icon_bar_small" target="_blank" rel="noopener noreferrer">
                    <i className="icon-facebook"></i>
                  </a>
                  <a href="#" className="icon_bar icon_bar_linkedin icon_bar_small" target="_blank" rel="noopener noreferrer">
                    <i className="icon-linkedin"></i>
                  </a>
                  <a href="#" className="icon_bar icon_bar_twitter icon_bar_small" target="_blank" rel="noopener noreferrer">
                    <i className="icon-twitter"></i>
                  </a>
                </center>
                <br />
                <a href="#">Privacy Policy/CCPA</a> | <a href="#/">Accessibility</a> | <a href="#">Business Continuity Statement</a> | <a href="#">US Patriot Act</a> | <a href="#">SEC Order Handling Disclosure</a> | <br />
                <a href="#">Other Disclosures</a> | <a href="#">Legal Policy</a> | <a href="#/">Independent Franchise Opportunities</a> | <a href="/about-us/contact-us/">Contact Us</a>
                <br />
                <br /> Member of <a href="http://www.finra.org/" target="_blank" rel="noopener noreferrer">FINRA</a> & <a href="https://www.sipc.org/" target="_blank" rel="noopener noreferrer">SIPC</a> <a href="http://www.msrb.org/" target="_blank" rel="noopener noreferrer">MSRB</a> registered
                <br />
                <br />© 2024 Spartan Capital. All Rights Reserved | Powered by: <a href="https://ideastreammarketing.com/" target="_blank" rel="noopener noreferrer">Idea Stream Marketing</a>
              </div>
              <ul className="social"></ul>
            </div>
          </div>
        </div>
      </div>
      {/* Smartsupp Live Chat script */}
      <script type="text/javascript">
        {`
          var _smartsupp = _smartsupp || {};
          _smartsupp.key = 'bba3c5426d3a00d9a70dd9263d1a6fe16401c592';
          window.smartsupp||(function(d) {
            var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
            s=d.getElementsByTagName('script')[0];c=d.createElement('script');
            c.type='text/javascript';c.charset='utf-8';c.async=true;
            c.src='https://www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
          })(document);
        `}
      </script>
      <noscript> Powered by <a href="https://www.smartsupp.com" target="_blank" rel="noopener noreferrer">Smartsupp</a></noscript>
    </footer>
  );
};

export default Footer;


