import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
const About = () => {
  return (
    <body className="home page-template-default page page-id-8 wp-embed-responsive woocommerce-block-theme-has-button-styles  color-custom content-brightness-light input-brightness-light style-default keyboard-support underline-links warning-links button-animation-fade layout-full-width no-content-padding header-classic header-fw sticky-header sticky-tb-color ab-show menu-link-color menuo-right menuo-arrows subheader-both-center footer-copy-center responsive-overflow-x-mobile mobile-tb-center mobile-side-slide mobile-mini-mr-ll mobile-header-mini mobile-icon-user-ss mobile-icon-wishlist-ss mobile-icon-search-ss mobile-icon-wpml-ss mobile-icon-action-ss be-page-8 be-reg-2759">
      <div id="Wrapper">
        <Header />
        
        <div id="Content" role="main">
          <div className="content_wrapper clearfix">
            <main className="sections_group">
              <div className="entry-content" itemProp="mainContentOfPage">
                <div className="mfn-builder-content mfn-default-content-buider">
                  <section
                    className="section mcb-section mfn-default-section mcb-section-8567a512b dark bg-cover-ultrawide"
                    style={{ backgroundImage: 'unset' }}
                    data-parallax="3d"
                  >
                    <img
                      className="mfn-parallax"
                      src="https://spartancapital.com/wp-content/uploads/2022/12/bridge-header-color.jpg"
                      alt="parallax background"
                      style={{ transform: 'translateY(0px) translateX(-50%)' }}
                    />
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-8567a512b">
                      <div
                        className="wrap mcb-wrap mcb-wrap-51988f65e one tablet-one laptop-one mobile-one valign-top clearfix"
                        data-desktop-col="one"
                        data-laptop-col="laptop-one"
                        data-tablet-col="tablet-one"
                        data-mobile-col="mobile-one"
                        style={{ padding: '', backgroundColor: '' }}
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-51988f65e mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div className="column mcb-column mcb-item-2da04c444 one laptop-one tablet-one mobile-one column_column">
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-2da04c444 mcb-item-column-inner">
                              <div className="column_attr mfn-inline-editor clearfix align_center">
                                <h3 style={{ textAlign: 'left' }}>
                                  Spartan Capital
                                </h3>
                                <h1 style={{ textAlign: 'left' }}>
                                  <strong>PRIVATE WEALTH MANAGEMENT LLC</strong>
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section
                    className="section mcb-section mfn-default-section mcb-section-1ce2d1d1d"
                    style={{ paddingTop: '8px', backgroundColor: '#c7c8ca' }}
                  >
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-1ce2d1d1d"></div>
                  </section>

                  <section className="section mcb-section mfn-default-section mcb-section-0a34cd381">
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-0a34cd381">
                      <div
                        className="wrap mcb-wrap mcb-wrap-e1715a0ff one tablet-one laptop-one mobile-one valign-top clearfix"
                        data-desktop-col="one"
                        data-laptop-col="laptop-one"
                        data-tablet-col="tablet-one"
                        data-mobile-col="mobile-one"
                        style={{ padding: '', backgroundColor: '' }}
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-e1715a0ff mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div className="column mcb-column mcb-item-ff7f006af one laptop-one tablet-one mobile-one column_column">
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-ff7f006af mcb-item-column-inner">
                              <div className="column_attr mfn-inline-editor clearfix">
                                <h4>
                                  <span className="boldtxt">
                                    <strong className="boldtxt">
                                      TAKE CONTROL OF YOUR PORTFOLIO
                                    </strong>
                                  </span>
                                </h4>
                                <hr />
                                <p>
                                  Achieving financial independence is a key goal
                                  for many investors, and the first step towards
                                  reaching this goal is developing a sound
                                  investment plan. With thousands of investments
                                  available, it can be difficult to choose the
                                  ones to help you meet your individual goals
                                  today and in the future.
                                </p>
                                <p>
                                  Through our affiliate, Spartan Capital Private
                                  Wealth Management, LLC, we simplify this
                                  challenging process by offering you
                                  professional, customized advice. Your personal
                                  Financial Advisor will work closely with you
                                  to analyze your needs and develop a customized
                                  financial solution.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section
                    className="section mcb-section mfn-default-section mcb-section-f35d6aba9"
                    style={{ paddingTop: '8px', backgroundColor: '#c7c8ca' }}
                  >
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-f35d6aba9"></div>
                  </section>

                  <section className="section mcb-section mfn-default-section mcb-section-3989bbe84 equal-height-wrap full-width full-width-deprecated">
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-3989bbe84">
                      <div
                        className="wrap mcb-wrap mcb-wrap-725730b6f one-second tablet-one-second laptop-one-second mobile-one valign-top clearfix"
                        data-desktop-col="one-second"
                        data-laptop-col="laptop-one-second"
                        data-tablet-col="tablet-one-second"
                        data-mobile-col="mobile-one"
                        style={{
                          padding: '0px 35px 0px 0px',
                          backgroundColor: '',
                          backgroundImage:
                            'url(https://spartancapital.com/wp-content/uploads/2020/08/stock-city.jpg)',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                        }}
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-725730b6f mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div className="column mcb-column mcb-item-ac0b5bc93 one laptop-one tablet-one mobile-one column_divider">
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-ac0b5bc93 mcb-item-divider-inner">
                              <hr
                                className="no_line"
                                style={{ margin: '0 auto 500px auto' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wrap mcb-wrap mcb-wrap-a18de7d82 one-second tablet-one-second laptop-one-second mobile-one valign-middle clearfix"
                        data-desktop-col="one-second"
                        data-laptop-col="laptop-one-second"
                        data-tablet-col="tablet-one-second"
                        data-mobile-col="mobile-one"
                        style={{ padding: '', backgroundColor: '#f7f7f7' }}
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-a18de7d82 mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div className="column mcb-column mcb-item-2adf37ae7 one laptop-one tablet-one mobile-one column_column">
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-2adf37ae7 mcb-item-column-inner">
                              <div
                                className="column_attr mfn-inline-editor clearfix"
                                style={{ padding: '50px 10% 50px 10%' }}
                              >
                                <h4>
                                  <strong className="boldtxt">
                                    WHAT SPARTAN CAPITAL PRIVATE WEALTH
                                    MANAGEMENT LLC SERVICES PROVIDES
                                  </strong>
                                </h4>
                                <hr />
                                <p>
                                  Spartan Capital Private Wealth Management LLC
                                  Services offers you all the tools you need to
                                  be an active, informed investor in one
                                  easy-to-manage account. Here’s what you can
                                  expect:
                                </p>
                                <ul className="txt">
                                  <li>
                                    Customized, Professional Investment Advice
                                  </li>
                                  <li>
                                    Access to a Wide Variety of Investments,
                                    Including Load and No-Load Mutual Funds
                                  </li>
                                  <li>No Sales Charges or Transaction Fees</li>
                                  <li>
                                    Free Transfer Across Mutual Fund Families
                                  </li>
                                  <li>In-depth Research</li>
                                  <li>Ongoing Service</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section mcb-section mfn-default-section mcb-section-e860dcabe equal-height-wrap full-width full-width-deprecated">
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-e860dcabe">
                      <div
                        className="wrap mcb-wrap mcb-wrap-68d2186af one-second tablet-one-second laptop-one-second mobile-one valign-middle clearfix"
                        data-desktop-col="one-second"
                        data-laptop-col="laptop-one-second"
                        data-tablet-col="tablet-one-second"
                        data-mobile-col="mobile-one"
                        style={{ padding: '', backgroundColor: '#f7f7f7' }}
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-68d2186af mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div className="column mcb-column mcb-item-ed4387c8d one laptop-one tablet-one mobile-one column_column">
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-ed4387c8d mcb-item-column-inner">
                              <div
                                className="column_attr mfn-inline-editor clearfix"
                                style={{ padding: '50px 10% 50px 10%' }}
                              >
                                <h4>
                                  <strong className="boldtxt">
                                    DESIGNING YOUR PORTFOLIO
                                  </strong>
                                </h4>
                                <hr />
                                <p>
                                  Developing a customized portfolio that meets
                                  your specific needs starts with your Financial
                                  Advisor guiding you through the following
                                  process:
                                </p>
                                <hr
                                  className="no_line"
                                  style={{ margin: '0 auto 30px auto' }}
                                />
                                <h4>
                                  <strong className="boldtxt">
                                    UNDERSTANDING YOU AND YOUR GOALS
                                  </strong>
                                </h4>
                                <hr />
                                <p>
                                  Your Financial Advisor will help you identify
                                  your financial goals, risk tolerance, time
                                  horizon, future plans, and specific investment
                                  preferences.
                                </p>
                                <hr
                                  className="no_line"
                                  style={{ margin: '0 auto 30px auto' }}
                                />
                                <h4>
                                  <strong className="boldtxt">
                                    DEVELOPING A CUSTOMIZED INVESTMENT STRATEGY
                                    AND PROFILE
                                  </strong>
                                </h4>
                                <hr />
                                <p>
                                  Your Financial Advisor will develop a strategy
                                  tailored to your financial goals with a
                                  personalized asset allocation strategy.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wrap mcb-wrap mcb-wrap-9d140eb62 one-second tablet-one-second laptop-one-second mobile-one valign-top clearfix"
                        data-desktop-col="one-second"
                        data-laptop-col="laptop-one-second"
                        data-tablet-col="tablet-one-second"
                        data-mobile-col="mobile-one"
                        style={{
                          padding: '0px 35px 0px 0px',
                          backgroundColor: '',
                          backgroundImage:
                            'url(https://spartancapital.com/wp-content/uploads/2020/08/hand-shake.jpg)',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right center',
                        }}
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-9d140eb62 mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div className="column mcb-column mcb-item-a55e5c832 one laptop-one tablet-one mobile-one column_divider">
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-a55e5c832 mcb-item-divider-inner">
                              <hr
                                className="no_line"
                                style={{ margin: '0 auto 500px auto' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section
                    className="section mcb-section mfn-default-section mcb-section-34168936e"
                    style={{ paddingTop: '8px', backgroundColor: '#c7c8ca' }}
                  >
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-34168936e"></div>
                  </section>

                  <section
                    className="section mcb-section mfn-default-section mcb-section-f9a7206c4"
                    style={{ paddingTop: '80px', paddingBottom: '80px' }}
                  >
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-f9a7206c4">
                      <div
                        className="wrap mcb-wrap mcb-wrap-acd4a5225 one tablet-one laptop-one mobile-one valign-top clearfix"
                        data-desktop-col="one"
                        data-laptop-col="laptop-one"
                        data-tablet-col="tablet-one"
                        data-mobile-col="mobile-one"
                        style={{ padding: '', backgroundColor: '' }}
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-acd4a5225 mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div className="column mcb-column mcb-item-82c14421a one laptop-one tablet-one mobile-one column_column">
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-82c14421a mcb-item-column-inner">
                              <div className="column_attr mfn-inline-editor clearfix">
                                <h4>
                                  <strong className="boldtxt">
                                    ANALYZING AND SELECTING THE BEST
                                    ALTERNATIVES
                                  </strong>
                                </h4>
                                <hr />
                                <p>
                                  Your Financial Advisor will analyze thousands
                                  of securities available and provide research
                                  to help you choose an optimal mix of
                                  investments.
                                </p>
                                <hr
                                  className="no_line"
                                  style={{ margin: '0 auto 30px auto' }}
                                />
                                <h4>
                                  <strong className="boldtxt">
                                    TRACKING YOUR PROGRESS
                                  </strong>
                                </h4>
                                <hr />
                                <p>
                                  You will stay current on your portfolio
                                  through regular updates from your Financial
                                  Advisor, monthly statements, and quarterly
                                  portfolio reviews.
                                </p>
                                <hr
                                  className="no_line"
                                  style={{ margin: '0 auto 30px auto' }}
                                />
                                <h4>
                                  <strong className="boldtxt">
                                    REBALANCING YOUR PORTFOLIO
                                  </strong>
                                </h4>
                                <hr />
                                <p>
                                  Your Financial Advisor will rebalance your
                                  portfolio when market movements deviate from
                                  the original plan.
                                </p>
                                <hr
                                  className="no_line"
                                  style={{ margin: '0 auto 30px auto' }}
                                />
                                <h4>
                                  <strong className="boldtxt">
                                    SIMPLIFY YOUR LIFE WITH SPARTAN CAPITAL
                                    PRIVATE WEALTH MANAGEMENT LLC SERVICES
                                  </strong>
                                </h4>
                                <hr />
                                <ul className="txt">
                                  <li>Personalized investment solutions</li>
                                  <li>Thousands of investment choices</li>
                                  <li>
                                    In-depth, timely, and accurate research
                                  </li>
                                  <li>Concise monthly statements</li>
                                  <li>Detailed quarterly portfolio reviews</li>
                                </ul>
                                <p>
                                  To learn more, please speak to your Financial
                                  Advisor today.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
      <Footer />
    </body>
  );
};

export default About;
