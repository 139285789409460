import React from 'react';
import Footer from '../components/Footer';
import SideMenu from '../components/SideMenu';
import Header from '../components/Header';

const Transactions = () => {
  return (
    <body className="home page-template-default page page-id-8 wp-embed-responsive woocommerce-block-theme-has-button-styles  color-custom content-brightness-light input-brightness-light style-default keyboard-support underline-links warning-links button-animation-fade layout-full-width no-content-padding header-classic header-fw sticky-header sticky-tb-color ab-show menu-link-color menuo-right menuo-arrows subheader-both-center footer-copy-center responsive-overflow-x-mobile mobile-tb-center mobile-side-slide mobile-mini-mr-ll mobile-header-mini mobile-icon-user-ss mobile-icon-wishlist-ss mobile-icon-search-ss mobile-icon-wpml-ss mobile-icon-action-ss be-page-8 be-reg-2759">
      <div id="Wrapper">
        <Header />
        <div id="Content" role="main">
          <div className="content_wrapper clearfix">
            <main className="sections_group">
              <div className="entry-content" itemProp="mainContentOfPage">
                <div className="mfn-builder-content mfn-default-content-builder">
                  <section
                    className="section mcb-section mfn-default-section mcb-section-cb8d69313 dark bg-cover-ultrawide"
                    style={{ backgroundImage: 'unset' }}
                    data-parallax="3d"
                  >
                    <img
                      className="mfn-parallax"
                      src="https://spartancapital.com/wp-content/uploads/2022/10/transactions-headers.jpg"
                      alt="parallax background"
                      style={{
                        transform: 'translateY(-34px) translateX(-50%)',
                      }}
                    />
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-cb8d69313">
                      <div
                        className="wrap mcb-wrap mcb-wrap-76a2d13fe one tablet-one laptop-one mobile-one valign-top clearfix"
                        data-desktop-col="one"
                        data-laptop-col="laptop-one"
                        data-tablet-col="tablet-one"
                        data-mobile-col="mobile-one"
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-76a2d13fe mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>
                          <div className="column mcb-column mcb-item-9cc5041d4 one laptop-one tablet-one mobile-one column_column">
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-9cc5041d4 mcb-item-column-inner">
                              <div
                                className="column_attr mfn-inline-editor clearfix align_center"
                                style={{ textAlign: 'left' }}
                              >
                                <h3>Spartan Capital</h3>
                                <h1>
                                  <strong>TRANSACTIONS</strong>
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  

                  <section
                    className="section mcb-section mfn-default-section mcb-section-4740b58c2"
                    style={{ paddingTop: '5px', backgroundColor: '#c7c8ca' }}
                  >
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-4740b58c2"></div>
                  </section>

                  <section
                    className="section mcb-section mfn-default-section mcb-section-7aec8ef1a equal-height"
                    style={{ paddingTop: '50px' }}
                  >
                    <div className="mcb-background-overlay"></div>
                    <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-7aec8ef1a">
                      <div
                        className="wrap mcb-wrap mcb-wrap-b211c61c7 one tablet-one laptop-one mobile-one valign-middle clearfix"
                        data-desktop-col="one"
                        data-laptop-col="laptop-one"
                        data-tablet-col="tablet-one"
                        data-mobile-col="mobile-one"
                      >
                        <div className="mcb-wrap-inner mcb-wrap-inner-b211c61c7 mfn-module-wrapper mfn-wrapper-for-wraps">
                          <div className="mcb-wrap-background-overlay"></div>

                          {/* Transaction Section 1 */}
                          <div
                            className="column mcb-column mcb-item-mvs3vcq3a one-fourth laptop-one-fourth tablet-one-fourth mobile-one column_column animate fadeInUp"
                            style={{ animationType: 'fadeInUp' }}
                          >
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-mvs3vcq3a mcb-item-column-inner">
                              <div
                                className="column_attr mfn-inline-editor clearfix align_center"
                                style={{
                                  backgroundColor: '#ffffff',
                                  padding: '10px 10%',
                                  border: '4px solid #e8e8e8',
                                }}
                              >
                                <img
                                  className="aligncenter"
                                  src="https://spartancapital.com/wp-content/uploads/2024/09/ag-eagle.jpg"
                                  alt=""
                                  width="300"
                                  height="165"
                                />
                                <img
                                  className="aligncenter"
                                  src="https://spartancapital.com/wp-content/uploads/2022/01/divider.png"
                                  alt=""
                                  width="150"
                                  height="5"
                                />
                                <hr
                                  className="no_line"
                                  style={{ margin: '0 auto 13px auto' }}
                                />
                                <h3>
                                  <strong>$6,500,000</strong>
                                </h3>
                                <hr
                                  className="no_line"
                                  style={{ margin: '0 auto 4px auto' }}
                                />
                                <h5>
                                  Follow On Offering
                                  <br />
                                  Sole Placement Agent
                                </h5>
                                <h5 style={{ color: '#243a5d' }}>
                                  <strong>SEPTEMBER&nbsp;2024</strong>
                                </h5>
                              </div>
                            </div>
                          </div>

                          {/* Transaction Section 2 */}
                          <div
                            className="column mcb-column mcb-item-7amt6ii2n one-fourth laptop-one-fourth tablet-one-fourth mobile-one column_column animate fadeInUp"
                            style={{ animationType: 'fadeInUp' }}
                          >
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-7amt6ii2n mcb-item-column-inner">
                              <div
                                className="column_attr mfn-inline-editor clearfix align_center"
                                style={{
                                  backgroundColor: '#ffffff',
                                  padding: '10px 10%',
                                  border: '4px solid #e8e8e8',
                                }}
                              >
                                <img
                                  className="aligncenter"
                                  src="https://spartancapital.com/wp-content/uploads/2023/04/alph-cognition-logo.jpg"
                                  alt=""
                                  width="300"
                                  height="165"
                                />
                                <img
                                  className="aligncenter"
                                  src="https://spartancapital.com/wp-content/uploads/2022/01/divider.png"
                                  alt=""
                                  width="150"
                                  height="5"
                                />
                                <hr
                                  className="no_line"
                                  style={{ margin: '0 auto 13px auto' }}
                                />
                                <h3>
                                  <strong>$4,454,000</strong>
                                </h3>
                                <hr
                                  className="no_line"
                                  style={{ margin: '0 auto 4px auto' }}
                                />
                                <h5>
                                  Convertible Note
                                  <br />
                                  Selling Group Member
                                </h5>
                                <h5 style={{ color: '#243a5d' }}>
                                  <strong>SEPTEMBER&nbsp;2024</strong>
                                </h5>
                              </div>
                            </div>
                          </div>

                          {/* Transaction Section 3 */}
                          <div
                            className="column mcb-column mcb-item-4qajqe9xp one-fourth laptop-one-fourth tablet-one-fourth mobile-one column_column animate fadeInUp"
                            style={{ animationType: 'fadeInUp' }}
                          >
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-4qajqe9xp mcb-item-column-inner">
                              <div
                                className="column_attr mfn-inline-editor clearfix align_center"
                                style={{
                                  backgroundColor: '#ffffff',
                                  padding: '10px 10%',
                                  border: '4px solid #e8e8e8',
                                }}
                              >
                                <img
                                  className="aligncenter"
                                  src="https://spartancapital.com/wp-content/uploads/2024/08/safe-pro.jpg"
                                  alt=""
                                  width="300"
                                  height="165"
                                />
                                <img
                                  className="aligncenter"
                                  src="https://spartancapital.com/wp-content/uploads/2022/01/divider.png"
                                  alt=""
                                  width="150"
                                  height="5"
                                />
                                <hr
                                  className="no_line"
                                  style={{ margin: '0 auto 13px auto' }}
                                />
                                <h3>
                                  <strong>$5,100,000</strong>
                                </h3>
                                <hr
                                  className="no_line"
                                  style={{ margin: '0 auto 4px auto' }}
                                />
                                <h5>
                                  IPO
                                  <br />
                                  Co-Manager
                                </h5>
                                <h5 style={{ color: '#243a5d' }}>
                                  <strong>AUGUST&nbsp;2024</strong>
                                </h5>
                              </div>
                            </div>
                          </div>

                          {/* Transaction Section 4 */}
                          <div
                            className="column mcb-column mcb-item-ozkhuk6dg one-fourth laptop-one-fourth tablet-one-fourth mobile-one column_column animate fadeInUp"
                            style={{ animationType: 'fadeInUp' }}
                          >
                            <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-ozkhuk6dg mcb-item-column-inner">
                              <div
                                className="column_attr mfn-inline-editor clearfix align_center"
                                style={{
                                  backgroundColor: '#ffffff',
                                  padding: '10px 10%',
                                  border: '4px solid #e8e8e8',
                                }}
                              >
                                <img
                                  className="aligncenter"
                                  src="https://spartancapital.com/wp-content/uploads/2024/08/jbdi-holdings-logo-new.jpg"
                                  alt=""
                                  width="300"
                                  height="165"
                                />
                                <img
                                  className="aligncenter"
                                  src="https://spartancapital.com/wp-content/uploads/2022/01/divider.png"
                                  alt=""
                                  width="150"
                                  height="5"
                                />
                                <hr
                                  className="no_line"
                                  style={{ margin: '0 auto 13px auto' }}
                                />
                                <h3>
                                  <strong>$11,250,000</strong>
                                </h3>
                                <h5>IPO</h5>
                                <h3>
                                  <strong>$7,067,115</strong>
                                </h3>
                                <h5>Selling Group Member</h5>
                                <h5 style={{ color: '#243a5d' }}>
                                  <strong>AUGUST&nbsp;2024</strong>
                                </h5>
                              </div>
                            </div>
                          </div>

                          {/* Add other transaction blocks similarly */}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
      <Footer />
    </body>
  );
};

export default Transactions;
